import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useColorTheme from "../../../../../hooks/useColorTheme";
import about from "assets/images/about.svg";

// Components
import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import ShowAllTrendAnalysis from "components/ShowAll/ShowAllTrendAnalysis";
import { Styles } from "./styles";
import HighestRated from "./components/HighestRated";
import TrendAnalysisTotalChart from "./components/TrendAnalysisTotalChart";
import TrendAnalysisCategoryChart from "./components/TrendAnalysisCategoryChart";

// Actions
import {
  setTrendAnalysisFilter,
  fetchTrendAnalysisChart,
  fetchTrendAnalysisChartAll,
  fetchTrendAnalysisMovers,
  fetchTrendAnalysisMoversAll,
} from "store/rating/trendAnalysis/actions";

// Utils
import { STATE_STATUSES } from "utils/statuses";
import { trendAnalysisFilters } from "constants/filters";
import { getTimePeriod } from "utils/getTimePeriod";

// Images
import { Cancel } from "assets/icons/icons";

const TrendAnalysisTop = ({ arrayOfDesiredBrand, setArrayOfDesiredBrand }) => {
  const { primaryColor } = useColorTheme();

  // Selectors
  const { trendAnalysis, trendAnalysisAll, trendAnalysisMovers, trendAnalysisMoversAll, filter } = useSelector(
    (state) => state.trendAnalysis
  );

  // Access individual statuses
  const trendAnalysisStatus = trendAnalysis.status;
  const trendAnalysisAllStatus = trendAnalysisAll.status;

  const { lastFilter } = useSelector((state) => state.authorization);

  // Dispatch
  const dispatch = useDispatch();
  const isBrand = filter[1].value === "brand";

  // Form query and dispatch actions to fetch data
  useEffect(() => {
    // Form query params for chart
    const chartParams = {
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
    };

    // Form query params for movers
    const moversParams = {
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      brands:
        filter[1].value === "brand" && arrayOfDesiredBrand.length
          ? arrayOfDesiredBrand.map(({ id }) => id).join("|")
          : "All",
      retailers:
        filter[1].value === "retailer" && arrayOfDesiredBrand.length
          ? arrayOfDesiredBrand.map(({ id }) => id).join("|")
          : "All",
    };

    if (filter[2].value) {
      dispatch(fetchTrendAnalysisChartAll(chartParams));
      dispatch(fetchTrendAnalysisMoversAll(moversParams));
    } else {
      dispatch(fetchTrendAnalysisChart(chartParams));
      dispatch(fetchTrendAnalysisMovers(moversParams));
    }
  }, [lastFilter, filter[1].value, filter[2].value, arrayOfDesiredBrand, dispatch]);

  // Clear selected brands/retailers when filter changes
  useEffect(() => {
    setArrayOfDesiredBrand([]);
  }, [filter[1]]);

  const setSelectValue = (values) => {
    const value = Object.keys(values)[0];

    const updatedFilter = filter.map((item) => {
      if (item.name === value) {
        return { ...item, value: values[value] };
      }
      return item;
    });

    dispatch(setTrendAnalysisFilter(updatedFilter));
  };

  const renderChart = () => {
    const data = filter[2].value ? trendAnalysisAll : trendAnalysis;
    if (filter[1].value === "total") {
      return <TrendAnalysisTotalChart data={data} filter={filter} />;
    } else {
      return (
        <TrendAnalysisCategoryChart
          data={data}
          filter={filter}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        />
      );
    }
  };

  const renderSelectedEntity = () => {
    const suffix = arrayOfDesiredBrand.length > 1 ? "s" : "";
    const entity = isBrand ? " brand" : " retailer";
    const content = arrayOfDesiredBrand.length + entity + suffix;

    if (arrayOfDesiredBrand.length) {
      return (
        <div className="filter" onClick={() => setArrayOfDesiredBrand([])}>
          <span className="filter-title">{content}</span>
          <Cancel fill={"#fff"} />
        </div>
      );
    }
  };

  return (
    <Styles color={primaryColor}>
      <>
        <ShowAllTrendAnalysis
          icon={about}
          text={"Show All Reviews"}
          onChangeHandler={setSelectValue}
          value={filter[2].value}
          disabled={
            filter[2].value
              ? trendAnalysisAllStatus !== STATE_STATUSES.READY
              : trendAnalysisStatus !== STATE_STATUSES.READY
          }
        />
        <div className="filters-box">
          {trendAnalysisFilters.map((item, index) => (
            <SelectBox
              filter={filter[index].value}
              key={index}
              data={item}
              setSelectValue={setSelectValue}
              disabled={
                filter[2].value
                  ? trendAnalysisAllStatus !== STATE_STATUSES.READY
                  : trendAnalysisStatus !== STATE_STATUSES.READY
              }
            />
          ))}
          {renderSelectedEntity()}
        </div>
        <div className="wrapper-box-relative">
          {filter[2].value ? (
            trendAnalysisAll.success && trendAnalysisMoversAll.success ? (
              <div className="chart-wrapper-box">
                {renderChart()}
                <HighestRated moversData={trendAnalysisMoversAll} />
              </div>
            ) : null
          ) : trendAnalysis.success && trendAnalysisMovers.success ? (
            <div className="chart-wrapper-box">
              {renderChart()}
              <HighestRated moversData={trendAnalysisMovers} />
            </div>
          ) : null}
          {(trendAnalysisAllStatus === STATE_STATUSES.PENDING || trendAnalysisStatus === STATE_STATUSES.PENDING) && (
            <LoaderBox />
          )}
        </div>
      </>
    </Styles>
  );
};

export default TrendAnalysisTop;
