import React, { useEffect, useState } from "react";
import useColorTheme from "../../../hooks/useColorTheme";
import useRetailers from "../../../hooks/useRetailers";

import moment from "moment";
//
import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import PopupAvailabilityTable from "./PopupAvailabilityTable";
import PopupAvailabilityChart from "./PopupAvailabilityChart";
import { notification } from "antd";

//Assets
import cancel2 from "assets/images/cancel2.svg";
//Utils
import { COLORS } from "assets/colors/colors";
import tescoTestScreen from "assets/images/tesco-test-screen.jpeg";
import smallBurger from "assets/images/burger-small.svg";
import snapShot from "assets/images/snapshot.svg";

const colors = [COLORS.available, COLORS.unavailable];

const tableData = {
  newlyListed: "This product is newly available to buy.",
  inStock: "This product is available to buy.",
  outOfStock: "This product is unavailable to buy.",
  "de-listed": "This product was not seen on the retailer",
  relisted: "This product was previously not seen but has been reseen",
};

const PopupAvailability = ({ onClose, data }) => {
  const { primaryColor } = useColorTheme();
  const { getRemoteLogo } = useRetailers();

  const [activeBtn, setActiveBtn] = useState(0);
  const [chartData, setChartData] = useState([]);
  const {
    item: { title, href, image, sourceType },
    period,
  } = data;
  const [selectedScreenShot, setSelectedScreenShot] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    const data = Object.entries(period);
    if (data.length) {
      const dataForChart = data.map((el) => {
        const status = el[1] === false ? "de-listed" : el[1].status;
        const isInStock = el[1] === false ? false : el[1].stock;

        return {
          date: el[0],
          products: 100,
          status,
          isInStock,
          color: isInStock ? colors[0] : colors[1],
          screenshot: el[1].screenshot,
        };
      });
      setChartData(dataForChart);
    }
  }, [period]);

  const onPointClick = (thisObj) => {
    const matchedEl = chartData.find(({ date }) => moment(date).valueOf() === moment(thisObj.category).valueOf()) || {};
    if (!Boolean(matchedEl?.screenshot?.length) && !Boolean(matchedEl?.isInStock)) {
      openNotification("error");
      return;
    }
    setSelectedScreenShot(matchedEl.screenshot);
    setIsImageLoading(true);
    setActiveBtn(1);
  };

  const openNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Screenshot has no link",
    });
  };

  const setButton = (num) => {
    setActiveBtn(num);
    setSelectedScreenShot("");
  };

  return (
    <Styles color={primaryColor}>
      <div className="popup-wrapper">
        <div className="popup-box">
          <img className="cancel" src={cancel2} alt="cancel" onClick={onClose} />
          <div className="header" style={{ marginBottom: activeBtn === 1 ? 10 : null }}>
            {activeBtn === 0 ? (
              <div className="product-img-wrapper">
                <img src={image} alt="" />
              </div>
            ) : null}
            <div className="header-text-wrapper">
              <span>{title}</span>
              {activeBtn === 0 && href?.length ? (
                <div className="see-on-website">
                  <img src={getRemoteLogo(sourceType)} alt="" />
                  <a target="blank" href={href}>
                    See on Retailer Website
                  </a>
                </div>
              ) : null}
            </div>
            {activeBtn === 1 ? (
              <div className="group-btns">
                <div
                  className="burger"
                  onClick={() => setButton(0)}
                  style={{
                    background: activeBtn === 0 ? primaryColor : COLORS.lightGray,
                  }}
                >
                  <img src={smallBurger} alt="" />
                </div>
                <div
                  className="snapshot"
                  style={{
                    background: activeBtn === 1 ? primaryColor : COLORS.lightGray,
                  }}
                  onClick={() => setButton(1)}
                >
                  <img src={snapShot} alt="" />
                </div>
              </div>
            ) : null}
          </div>
          {activeBtn === 0 ? (
            <div className="scroll">
              {chartData.length ? (
                <PopupAvailabilityChart
                  data={chartData}
                  title={title}
                  onPointClick={onPointClick}
                  setSelectedScreenShot={setSelectedScreenShot}
                />
              ) : null}
              <PopupAvailabilityTable data={tableData} />
            </div>
          ) : (
            <div className="scroll snapshot">
              {/* <span className="screenshot-text">Screenshot</span> */}
              <div className="screenshot">
                {isImageLoading ? <LoaderBox style={{ top: 82 }} /> : null}
                <img
                  onLoad={() => setIsImageLoading(false)}
                  src={selectedScreenShot}
                  alt=""
                  className="screenshot-img"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
};

export default PopupAvailability;
