import amazon from "assets/images/company-logo/amazon.svg";
import asda from "assets/images/company-logo/asda.svg";
import morrisons from "assets/images/company-logo/morrisons.svg";
import sainsburys from "assets/images/company-logo/sainsburys.svg";
import tesco from "assets/images/company-logo/tesco.svg";
import waitrose from "assets/images/company-logo/waitrose.svg";
import iceland from "assets/images/company-logo/iceland.svg";
import ocado from "assets/images/company-logo/ocado.svg";
import amazon_fresh from "assets/images/company-logo/amazon_fresh.svg";
import coop from "assets/images/company-logo/coop.svg";
import bm from "assets/images/company-logo/b&m.svg";
import home_bargains from "assets/images/company-logo/home_bargains.svg";
import aldi from "assets/images/company-logo/aldi.svg";
import boots from "assets/images/company-logo/boots.svg";
import superdrug from "assets/images/company-logo/superdrug.svg";
import currys from "assets/images/company-logo/currys.svg";
import very from "assets/images/company-logo/very.svg";
import ao from "assets/images/company-logo/ao.svg";
import argos from "assets/images/company-logo/argos.svg";

import good from "assets/images/good.svg";
import attention from "assets/images/attention.svg";
import bad from "assets/images/bad.svg";
import brandNudge from "assets/images/brandNudge.svg";
import search from "assets/images/search.svg";
import burger from "assets/images/grey-burger.svg";

import flagImgUk from "assets/images/Flag-United-Kingdom.svg";
import flagImgIreland from "assets/images/Flag-Ireland.svg";
import flagImgFrance from "assets/images/Flag-France.svg";

export const defaultFlag = flagImgUk;

export const getImage = (imageName, imageUrl) => {
  const images = {
    amazon,
    asda,
    morrisons,
    sainsburys,
    tesco,
    waitrose,
    iceland,
    ocado,
    "amazon fresh": amazon_fresh,
    amazon_fresh,
    coop,
    "b&m": bm,
    "home bargains": home_bargains,
    home_bargains,
    aldi,
    boots,
    superdrug,
    currys,
    very,
    ao,
    good,
    attention,
    bad,
    brandNudge,
    search,
    burger,
    argos,
    IE: flagImgIreland,
    GB: flagImgUk,
    FR: flagImgFrance,
  };

  return imageUrl || images[imageName];
};
