import React, { useState, useEffect, useRef, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Popover } from "antd";
import moment from "moment";

//Components
import RenderNoData from "components/RenderNoData";
import BrandLabelCard from "components/BrandLabelCard";
import RowOfBrands from "components/RowOfBrands";
import { changeNameRetailer } from "utils/changeNameRetailer";

const TrendAnalysisCategoryChart = (props) => {
  const { data, filter, arrayOfDesiredBrand, setArrayOfDesiredBrand } = props;
  //Refs
  const inputRef = useRef(null);
  const moreBrandsRef = useRef(null);
  //States
  const [dates, setDates] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [names, setNames] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [isMoreBtnClicked, setMoreBtnClick] = useState(false);
  const [isTooMoreData, setIsTooMoreData] = useState(false);
  //Constants
  const isBrand = filter[1].value === "brand";
  const byReviews = filter[0].value === "count";
  const renderLabelsStep = 16;
  const MORE = "more";
  const desiredArr = arrayOfDesiredBrand;
  const setFunc = setArrayOfDesiredBrand;
  const title = byReviews ? "No. of Reviews" : "Avg. Rating";

  useEffect(() => {
    const currentData = data.data[filter[1].value].chart[filter[0].value];
    let resultArr = [];
    if (desiredArr.length) {
      for (let index = 0; index < currentData.length; index++) {
        const element = currentData[index];
        const filteredData = element[1].filter(({ item }) => desiredArr.some((el) => el.name === item.name));
        resultArr = [...resultArr.slice(0, index), [element[0], filteredData], ...resultArr.slice(index + 1)];
      }
      setFilteredData(resultArr);
    } else {
      setFilteredData(currentData);
    }
  }, [desiredArr, data, filter]);

  useEffect(() => {
    const currentData = data.data[filter[1].value].chart[filter[0].value];
    if (filteredData.length && currentData.length) {
      const datesMoment = data.data.days.map((item) => moment(item).format("DD MMM YYYY"));
      setDates(datesMoment);
      const names = [...new Set(currentData.map((item) => item[1].map((el) => el.item.name)).flat())];
      const ids = [...new Set(currentData.map((item) => item[1].map((el) => el.item.id)).flat())];
      const filteredNames = [...new Set(filteredData.map((item) => item[1].map((el) => el.item.name)).flat())];
      const colors = [...new Set(currentData.map((item) => item[1].map((el) => el.item.color)).flat())];
      const namesWithColorsAndIds = names.map((el, index) => ({
        name: el,
        color: colors[index],
        id: ids[index],
      }));
      setNames(namesWithColorsAndIds);

      let resultArr = filteredNames.map((name) => ({ name, data: [] }));

      for (let index = 0; index < filteredData.length; index++) {
        const elementArr = filteredData[index][1];
        for (let index = 0; index < elementArr.length; index++) {
          const element = elementArr[index];

          const indexOfMatchedEl = resultArr.findIndex(({ name }) => name === element.item.name);
          if (indexOfMatchedEl !== -1) {
            resultArr = [
              ...resultArr.slice(0, indexOfMatchedEl),
              {
                name: resultArr[indexOfMatchedEl]?.name,
                data: [...resultArr[indexOfMatchedEl]?.data, element.data],
                color: element.item.color,
                className: isTooMoreData ? "not-hightlighted" : "hightlighted",
              },
              ...resultArr.slice(indexOfMatchedEl + 1),
            ];
          }
        }
      }
      setPricings(resultArr);
    }
  }, [filteredData, filter, isTooMoreData, data]);

  useEffect(() => {
    if (pricings.length) {
      pricings.forEach(({ data }) => {
        if (data.length) {
          setEmptyData(false);
        } else {
          setEmptyData(true);
        }
      });
    } else {
      setEmptyData(true);
    }
  }, [pricings]);

  useEffect(() => {
    if (names.length > renderLabelsStep) {
      if (!desiredArr.length) {
        setIsTooMoreData(true);
      } else {
        setIsTooMoreData(false);
      }
    } else {
      setIsTooMoreData(false);
    }
  }, [names, isBrand, desiredArr]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      height: 300,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        fillColor: 0,
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(dates.length / 3),
      },
      categories: dates,
    },
    yAxis: {
      title: {
        offset: 20,
        x: 25,
        text: title,
      },
      offset: 28,

      startOnTick: byReviews ? true : false,
      endOnTick: byReviews ? true : false,
      min: byReviews ? null : 0,
      softMax: byReviews ? null : 5,
      labels: {
        formatter: function () {
          const value = this.value;
          if (value >= 1000) {
            return value / 1000 + "k";
          } else {
            return value;
          }
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      zIndex: 999,
      formatter: function () {
        let initialData = this.points.map((item) => {
          return `<div class='wrapper'>
                                        <div class='box'>
                                            <div class='color' style='background: ${item.color}' ></div>
                                            <div class='name'>${changeNameRetailer(item.series.options.name)}</div>
                                        </div>
                                   
                                <div class='price'>${item.y}</div>
                            </div>`;
        });
        initialData = initialData.join("");

        return `<div class='wrapper-category'>
                        <div class='title'>${title}</div>
                        <div class='wrapper-box'>
                            <div>
                                <div class='date'>${moment(this.points[0].key).format("DD MMM YY")}</div>
                                <div>
                                    ${initialData}
                                </div>
                        </div>
                    </div>`;
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  const renderTooMoreData = () => {
    return (
      <div className={isTooMoreData ? "too-more-data" : "display-null"} style={{ top: 35, height: 300 }}>
        <p>Looks like we have a lot of data.</p>
        <p>Select the buttons below to see the graph.</p>
      </div>
    );
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "calc(100% - 412px)" }}>
      <div className="chart-title-desc">Select a product to see its reviews.</div>
      {renderTooMoreData()}

      {emptyData ? (
        <RenderNoData style={{ height: 335 }} />
      ) : (
        <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} />
      )}
      <div className="brand-labels-row">
        <RowOfBrands
          names={names}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          withId
        />
      </div>
    </div>
  );
};

export default TrendAnalysisCategoryChart;
