import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "antd";
import useColorTheme from "../../../hooks/useColorTheme";

import { inactiveMetrics, activeMetrics } from "components/Alerts/alertTemplate";

const MetricsAler = ({
  item,
  selectItems,
  setMetricsParamsSlider,
  setMetricsParamsCheckbox,
  isMetric,
  setIsCheckMetric,
  toggleMetricsParams,
}) => {
  const { primaryColor } = useColorTheme();
  const { name, icon, label, section } = item;

  const wrapperRef = useRef(null);

  const { sections } = useSelector((state) => state.authorization);

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleChangeMetric = () => {
    if (!isMetric) {
      setOpenDropdown(true);
      toggleMetricsParams(name, activeMetrics[name]);
    } else {
      setOpenDropdown(false);
      toggleMetricsParams(name, activeMetrics[name]);
    }
    setIsCheckMetric(name, !isMetric);
  };

  const resetAllParams = () => {
    toggleMetricsParams(name, inactiveMetrics[name]);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (openDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpenDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, openDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} className="wrapper-custom-select-box">
      <div className="wrapper-custom-select">
        <Checkbox
          style={{ backgroundColor: openDropdown ? "#d9dfe6" : "#eef2f7" }}
          disabled={
            !(
              sections.find((item) => item.name === section) || {
                status: sections.find((item) => item.name === "availability").status,
              }
            ).status
          }
          checked={isMetric}
          onChange={handleChangeMetric}
        >
          <div className="label">{label}</div>
          <div className="hint-selected-item">{icon}</div>
        </Checkbox>
      </div>
      <button
        style={{ color: isMetric ? primaryColor : "#9696a0" }}
        className="set-details"
        disabled={!isMetric}
        onClick={() => setOpenDropdown(true)}
      >
        Set Details
      </button>
      {openDropdown && selectItems ? (
        <div className="custom-select-dropdown">
          <div className="slider-wrapper">
            {item.hasOwnProperty("title") ? <div className="title">{item.title}</div> : null}

            {item.hasOwnProperty("checkbox")
              ? item.checkbox.map((item, index) => (
                  <item.component
                    key={index}
                    label={item.label}
                    value={item.subName ? selectItems[item.name][item.subName] : selectItems[item.name]}
                    setValue={(value) => setMetricsParamsCheckbox(name, item.name, item.subName, value)}
                  />
                ))
              : null}

            {item.hasOwnProperty("slider")
              ? item.slider.map((item, index) => (
                  <div key={index} style={{ marginTop: "36px", marginBottom: "24px" }}>
                    <div className="slider-value">{item.label}</div>
                    {
                      <item.component
                        values={selectItems[item.name]}
                        setValues={(values) => setMetricsParamsSlider(name, item.name, values)}
                      />
                    }
                  </div>
                ))
              : null}
          </div>
          <div className="control-select-btn">
            <div onClick={resetAllParams}>Reset</div>
            <div className="apply" onClick={() => setOpenDropdown(false)}>
              Apply
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MetricsAler;
