import React, { useEffect, useState, useRef } from "react";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import about from "assets/images/about.svg";

// Components
import { Styles } from "./styles";
import ProductInsightSearch from "./components/ProductInsightSearch";
import ProductInsightProductCard from "./components/ProductInsightProductCard";
import ProductInsightTable from "./components/ProductInsightTable";
import ShowPerPage from "components/ShowPerPage";
import LoaderBox from "components/LoaderBox";
import ShowAll from "components/ShowAll";
import useFilters from "../../../../hooks/useFilters";

// Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";

// Actions
import {
  fetchProductInsight,
  fetchProductInsightAll,
  fetchProductInsightReviews,
  fetchProductInsightReviewsAll,
  setProductInsightFilter
} from "store/rating/productInsight/actions";

const ProductInsight = () => {
  // Selectors
  const {
    statusFilters,
    lastFilter,
  } = useFilters();

  const {
    productInsight,
    productInsightAll,
    productInsightReviews,
    productInsightReviewsAll,
    filter
  } = useSelector((state) => state.productInsight);

  const productInsightStatus = productInsight.status;
  const productInsightAllStatus = productInsightAll.status;

  // States
  const [productSearch, setProductSearch] = useState("");
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [queryParamsTable, setQueryParamsTable] = useState({});
  const [sortId, setSortId] = useState("date");
  const [sortDirection, setSortDirection] = useState(false);
  const [data, setData] = useState({});
  const [filteredProductsArr, setFilteredProdictsArr] = useState([]);

  // Constants
  const dispatch = useDispatch();
  const coreRetailerIds = selectedProduct?.coreRetailerIds?.join("|");

  const baseQuery = {
    sourceType: lastFilter.sourceType,
    coreRetailerIds,
    limit: 10,
    page: 1,
    sort: "date|desc",
    timePeriod: getTimePeriod(lastFilter.date)
  };

  // Set empty data for dropdown
  useEffect(() => {
    setFilteredProdictsArr([]);
  }, [productInsight, productInsightAll]);

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: filter[1].value ? "0" : getTimePeriod(lastFilter.date),
      product: lastFilter.product,
    }));
  }, [lastFilter, filter[1].value]);

  useEffect(() => {
    if (filter[1].value) {
      if (Object.keys(queryParams).length) {

        if (filter[1].value) {
            dispatch(fetchProductInsightAll(queryParams));
        } 
        

        setSelectedProduct({});
        setProductSearch("");
        setIsBtnClicked(false);
      }
    }
  }, [queryParams, statusFilters, filter[1].value, dispatch]);

  useEffect(() => {
    if (!filter[1].value) {
      if (Object.keys(queryParams).length) {

        if (!filter[1].value) {
            dispatch(fetchProductInsight(queryParams));
        } 

        setSelectedProduct({});
        setProductSearch("");
        setIsBtnClicked(false);
      }
    }
  }, [queryParams, statusFilters, filter[1].value, dispatch]);

  const handleSort = (id) => {
    setSortDirection(!sortDirection);
    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;
    setQueryParamsTable((prevState) => ({
      ...baseQuery,
      ...prevState,
      sort,
    }));
  };

  const changeLimitHandler = (limit) => {
    setQueryParamsTable((prevState) => ({
      ...baseQuery,
      ...prevState,
      page: 1,
      limit,
    }));
  };

  const changePageHandler = (page) => {
    setQueryParamsTable((prevState) => ({
      ...baseQuery,
      ...prevState,
      page,
    }));
  };

  useEffect(() => {
    if (filter[1].value) {
      setData(Object.entries(productInsightAll.data));
      setSelectedProduct({});
      setProductSearch("");
    } else {
      setData(Object.entries(productInsight.data));
      setSelectedProduct({});
      setProductSearch("");
    }
  }, [productInsight, productInsightAll, filter[1].value]);

  useEffect(() => {
    if (filter[1].value) {
      if (Object.entries(queryParamsTable).length && isBtnClicked && queryParamsTable.coreRetailerIds) {
        dispatch(fetchProductInsightReviewsAll(queryParamsTable));
      }
    }
  }, [queryParamsTable, isBtnClicked, filter[1].value, dispatch]);

  useEffect(() => {
    if (!filter[1].value) {
      if (Object.entries(queryParamsTable).length && isBtnClicked && queryParamsTable.coreRetailerIds) {
        dispatch(fetchProductInsightReviews(queryParamsTable));
      }
    }
  }, [queryParamsTable, isBtnClicked, filter[1].value, dispatch]);

  // Search input field dropdown data
  useEffect(() => {
    setIsBtnClicked(false);

    if (data.length && productSearch.length) {
      const searchWords = productSearch.toLowerCase().split(" ");

      const result = data
        .map((el) => el[1])
        .filter(({ title }) => {
          const allWordsIncluded = searchWords.every(word => title?.toLowerCase().includes(word));
          return allWordsIncluded;
        });
      if (result.length) {
        setFilteredProdictsArr(result);
      } else {
        setSelectedProduct({});
        setFilteredProdictsArr(["empty"]);
      }
    } else {
      // When product search is empty reset dropdown data
      setFilteredProdictsArr([]);
    }

  }, [productSearch, data]);

  const clickBtnHandler = (boolean) => {
    setIsBtnClicked(boolean);
    setQueryParamsTable((prevState) => ({ ...prevState, ...baseQuery }));
    setSortDirection(false);
    setSortId("date");
  };

  // Set values for filters
  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });

    dispatch(setProductInsightFilter(updateData));
  };

  return (
    <Styles>
      <ShowAll
        icon={about}
        text={"Show All Reviews"}
        onChangeHandler={setSelectValue}
        value={filter[1].value}
      />
      <div className="wrapper-box-relative">
        <span className="header">Select a product to see its reviews.</span>

        <ProductInsightSearch
          handleSearch={(value) => setProductSearch(value)}
          productArr={filteredProductsArr}
          setProduct={setSelectedProduct}
          setDropdownVisible={setDropdownVisible}
          dropdownVisible={dropdownVisible}
          product={selectedProduct}
          searchForProd={productSearch}
          setIsBtnClicked={(val) => clickBtnHandler(val)}
        />
        {Object.entries(selectedProduct).length && isBtnClicked ? <ProductInsightProductCard product={selectedProduct} /> : null}
        {isBtnClicked && Object.entries(selectedProduct).length ? (
          <ProductInsightTable data={filter[1].value ? productInsightReviewsAll.reviews : productInsightReviews.reviews} handleSort={handleSort} sortId={sortId} sortDirection={sortDirection} />
        ) : null}

        {filter[1].value ? (
          productInsightReviewsAll.total > 0 && Object.entries(selectedProduct).length && isBtnClicked ? (
            <Pagination
              className="pagination-controls"
              onChange={changePageHandler}
              current={queryParamsTable.page ? queryParamsTable.page : 1}
              pageSize={queryParamsTable.limit ? queryParamsTable.limit : 10}
              total={productInsightReviewsAll.total}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
            />
          ) : null
        ) : (
          productInsightReviews.total > 0 && Object.entries(selectedProduct).length && isBtnClicked ? (
            <Pagination
              className="pagination-controls"
              onChange={changePageHandler}
              current={queryParamsTable.page ? queryParamsTable.page : 1}
              pageSize={queryParamsTable.limit ? queryParamsTable.limit : 10}
              total={productInsightReviews.total}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
            />
          ) : null
        )}

        {filter[1].value ? (
          productInsightReviewsAll.total > 0 && Object.entries(selectedProduct).length && isBtnClicked ? (
            <ShowPerPage setLimit={changeLimitHandler} value={queryParamsTable.limit ? queryParamsTable.limit : 10} />
          ) : null
        ) : (
          productInsightReviews.total > 0 && Object.entries(selectedProduct).length && isBtnClicked ? (
            <ShowPerPage setLimit={changeLimitHandler} value={queryParamsTable.limit ? queryParamsTable.limit : 10} />
          ) : null
        )}

        {filter[1].value ? (
          productInsightAllStatus !== STATE_STATUSES.READY && <LoaderBox />
        ) : (
          productInsightStatus !== STATE_STATUSES.READY && <LoaderBox />
        )}
      </div>
    </Styles>
  );
};

export default ProductInsight;
