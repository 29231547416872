import React, { useState, useEffect, useRef, useMemo } from "react";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import RenderNoData from "components/RenderNoData";
import snapShotDark from "assets/images/snapshot-dark.svg";
// import { COLORS } from "assets/colors/colors";
HC_rounded(Highcharts);

const keyStatuses = {
  newly: "Newly Listed",
  listedtrue: "In Stock",
  listedfalse: "Out of Stock",
  "de-listed": "Not Seen",
  "re-listed": "Reseen",
};

const PopupAvailabilityChart = ({ data, title, onPointClick }) => {
  const [datesArr, setDatesArr] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [chartWidth, setChartWidth] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    pricings?.data?.length * 25 > ref?.current?.offsetWidth
      ? setChartWidth(pricings?.data?.length * 25)
      : setChartWidth(ref?.current?.offsetWidth);
  }, [ref, pricings]);

  useEffect(() => {
    if (data.length) {
      setEmptyData(false);
      const dates = data.filter((item) => item.status?.length).map(({ date }) => moment(date).format("DD MMM YY"));
      setDatesArr(dates);

      const pricing = data
        .filter((item) => item.status?.length)
        .map(({ products, color, status, isInStock }) => ({
          y: products,
          color,
          status,
          isInStock,
        }));
      setPricings({
        name: "Products",
        data: pricing,
        borderRadiusTopLeft: "30%",
        borderRadiusTopRight: "30%",
      });
    } else {
      setEmptyData(true);
    }
  }, [data]);

  const options = {
    chart: {
      animation: false,
      height: 120,
      width: chartWidth,
      type: "column",
      style: {
        fontSize: "12px",
        fontFamily: "Gilroy-Medium",
        color: "#737283",
      },

      spacing: [5, 20, 5, 20],
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: datesArr,
      title: {
        text: null,
      },
      type: "datetime",
      labels: {
        step: parseInt(datesArr.length / 3),
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        stacking: "normal",
        pointWidth: 12,
        groupPadding: 0.2,
        borderWidth: 0,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        point: {
          events: {
            click() {
              return this.status === "de-listed" ? onPointClick(this) : null;
            },
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      hideDelay: 0,
      shadow: false,
      outside: true,

      formatter: function () {
        const status =
          this.points[0].point.status === "listed"
            ? this.points[0].point.status + this.points[0].point.isInStock
            : this.points[0].point.status;
        return `<div class="wrapper-category-availability">
        <div class="title">${title}</div>
        <div class="wrapper-box-availability">
          <div class="column">
            <div class="date">${this.x}</div>
            <div class="availability">
              <div class="color" style="background: ${this.points[0].color}"></div>
              <div>${this.points[0].point.isInStock ? "Available" : "Unavailable"} - ${keyStatuses[status]}</div>
            </div>
            ${
              !this.points[0].point.isInStock && this.points[0].point.status !== "listed"
                ? `<div class="show-details">Click on bar to see screenshot</div>`
                : ""
            }
          </div>
          ${
            !this.points[0].point.isInStock && this.points[0].point.status !== "listed"
              ? `<div class="screenshot-wrapper">
            <div>
              <img src="${snapShotDark}" alt="" />
            </div>
          </div>`
              : ""
          }
        </div>
      </div>`;
      },
    },

    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: pricings,
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }} ref={ref}>
      {emptyData ? (
        <RenderNoData style={{ height: 90 }} />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ className: "enableOverflow" }} />
      )}
    </div>
  );
};

export default PopupAvailabilityChart;
