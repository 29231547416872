import { success, error } from "redux-saga-requests";
import {
  FETCH_PRODUCT_INSIGHT,
  FETCH_PRODUCT_INSIGHT_ALL,
  FETCH_PRODUCT_INSIGHT_REVIEWS,
  FETCH_PRODUCT_INSIGHT_REVIEWS_ALL,
  SET_RATING_PRODUCT_INSIGHT_FILTER,
  SET_PRISTINE_PRODUCT_INSIGHT,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "byRetailer",
    value: "byRetailer",
  },
  { name: "showAll", value: false },
];

const initialState = {
  productInsight: {
    data: {},
    status: STATE_STATUSES.READY,
  },
  productInsightAll: {
    data: {},
    status: STATE_STATUSES.READY,
  },
  productInsightReviews: {
    success: false,
    reviews: [],
    total: 0,
  },
  productInsightReviewsAll: {
    success: false,
    reviews: [],
    total: 0,
  },
  filter: localStorage.getItem(SET_RATING_PRODUCT_INSIGHT_FILTER)
    ? JSON.parse(localStorage.getItem(SET_RATING_PRODUCT_INSIGHT_FILTER))
    : defaultFilter,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_INSIGHT:
      return {
        ...state,
        productInsight: {
          ...state.productInsight,
          status: STATE_STATUSES.PENDING,
        },
        error: null,
      };
    case FETCH_PRODUCT_INSIGHT_ALL:
      return {
        ...state,
        productInsightAll: {
          ...state.productInsightAll,
          status: STATE_STATUSES.PENDING,
        },
        error: null,
      };
    case FETCH_PRODUCT_INSIGHT_REVIEWS: {
      return processReducerReviews(state);
    }
    case FETCH_PRODUCT_INSIGHT_REVIEWS_ALL: {
      return processReducerReviewsAll(state);
    }
    case success(FETCH_PRODUCT_INSIGHT):
      return {
        ...state,
        productInsight: {
          data: action.data,
          status: STATE_STATUSES.READY,
        },
        isPristine: false,
      };
    case success(FETCH_PRODUCT_INSIGHT_ALL):
      return {
        ...state,
        productInsightAll: {
          data: action.data,
          status: STATE_STATUSES.READY,
        },
      };
    case success(FETCH_PRODUCT_INSIGHT_REVIEWS): {
      return {
        ...state,
        productInsightReviews: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_PRODUCT_INSIGHT_REVIEWS_ALL): {
      return {
        ...state,
        productInsightReviewsAll: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_PRODUCT_INSIGHT):
      return {
        ...state,
        productInsight: {
          data: {},
          status: STATE_STATUSES.ERROR,
        },
        error: action.error.response,
      };
    case error(FETCH_PRODUCT_INSIGHT_ALL):
      return {
        ...state,
        productInsightAll: {
          data: {},
          status: STATE_STATUSES.ERROR,
        },
        error: action.error.response,
      };
    case error(FETCH_PRODUCT_INSIGHT_REVIEWS): {
      const error = action.error.response;
      return errorReducerReviews(state, error);
    }
    case error(FETCH_PRODUCT_INSIGHT_REVIEWS_ALL): {
      const error = action.error.response;
      return errorReducerReviewsAll(state, error);
    }
      
    case SET_RATING_PRODUCT_INSIGHT_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_PRISTINE_PRODUCT_INSIGHT:
      return {
        ...state,
        isPristine: action.payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  productInsight: {
    success: false,
  },
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  productInsight: {
    success: false,
  },
  error: null,
});

const errorReducerReviews = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  productInsightReviews: {
    ...state.productInsightReviews,
    success: false,
  },
  error,
});
const processReducerReviews = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  productInsightReviews: {
    ...state.productInsightReviews,
    success: false,
  },
  error: null,
});

const errorReducerReviewsAll = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  productInsightReviewsAll: {
    ...state.productInsightReviewsAll,
    success: false,
  },
  error,
});
const processReducerReviewsAll = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  productInsightReviewsAll: {
    ...state.productInsightReviewsAll,
    success: false,
  },
  error: null,
});
