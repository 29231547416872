import React from "react";
import { Empty } from "antd";

const RenderNoData = ({ style, data }) => {
  if (data) {
    const productsData = data.products;

    // If productsData is defined and has items, render the first message
    if ((productsData && productsData.length) || (typeof productsData === "number" && productsData !== 0)) {
      return (
        <div className="emptyData" style={style}>
          <Empty description={<span>Select the buttons below to see the graph</span>} />
        </div>
      );
    }
  }

  // Default return if no data or productsData is empty
  return (
    <div className="emptyData" style={style}>
      <Empty description={<span>Looks like we do not have data for this request</span>} />
    </div>
  );
};

export default RenderNoData;
