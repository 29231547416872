import React from "react";

import { Styles } from "./styles";

import productImage from "assets/images/testImage.png";
import star from "assets/images/star.svg";

const ProductInsightProductCard = ({ product }) => {
  return (
    <Styles>
      <div className="imgWrapper">
        <img
          className="productImg"
          src={product.image ? product.image : productImage}
          alt=""
        />
      </div>
      <div className="column">
        <div className="title-wrapper">
          <span className="title">{product.title}</span>
        </div>
        <div className="row">
          <div className="column half">
            <div className="row">
              <span className="title">{product.avgRating}</span>{" "}
              <img src={star} alt="" />
            </div>
            <div className="row">
              <span className="text">Average Rating</span>
            </div>
          </div>
          <div className="column half">
            <div className="row">
              <span className="title">{product.reviewCount}</span>
            </div>
            <div className="row">
              <span className="text">Reviews</span>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default ProductInsightProductCard;
