import React from "react";
import { Pricing, Promotions, Availability, Content, Email, Sms, WhatsApp } from "assets/icons/icons";
import SliderBox from "./components/SliderBox";
import CheckboxBox from "./components/CheckboxBox";
import { COLORS } from "../../assets/colors/colors";

export const alertTemplate = (
  sourceType,
  category,
  manufacture,
  productBrand,
  product,
  themeColor = COLORS.primaryBlue,
  currencyName = "Pound",
  currencySymbol = "£"
) => ({
  filters: [
    {
      name: "sourceType",
      label: "Retailers",
      placeholder: "retailer",
      nameParam: "label",
      data: sourceType,
    },
    {
      name: "category",
      label: "Categories",
      placeholder: "category",
      nameParam: "name",
      data: category,
    },
    {
      name: "manufacture",
      label: "Manufacturers",
      placeholder: "manufacturer",
      nameParam: "name",
      data: manufacture,
    },
    {
      name: "productBrand",
      label: "Brands",
      placeholder: "brand",
      nameParam: "name",
      data: productBrand,
    },
    {
      name: "product",
      label: "Products",
      placeholder: "product",
      nameParam: "title",
      data: product,
    },
  ],
  metrics: [
    {
      title: "Change greater than",
      name: "pricing",
      label: "Base Price",
      icon: <Pricing fill={themeColor} />,
      section: "pricing",
      slider: [
        {
          name: "changeRange",
          label: "Percent (%)",
          component: SliderBox,
        },
        {
          name: "changeRangePercent",
          label: `${currencyName} (${currencySymbol})`,
          component: SliderBox,
        },
      ],
    },
    {
      title: "Type",
      name: "promotion",
      label: "Promotions",
      icon: <Promotions fill={themeColor} />,
      section: "promotions",
      checkbox: [
        {
          name: "mechanic",
          subName: "priceCut",
          label: "Price Cut",
          component: CheckboxBox,
        },
        {
          name: "mechanic",
          subName: "multiBuy",
          label: "Multi Buy",
          component: CheckboxBox,
        },
        {
          name: "mechanic",
          subName: "other",
          label: "Other",
          component: CheckboxBox,
        },
      ],
      slider: [
        {
          name: "discountRangePercent",
          label: "Discount (%)",
          component: SliderBox,
        },
      ],
    },
    {
      name: "availability",
      label: "Availability",
      icon: <Availability fill={themeColor} />,
      section: "availability",
      checkbox: [
        {
          name: "available",
          label: "Available",
          component: CheckboxBox,
        },
        {
          name: "unavailable",
          label: "Unavailable",
          component: CheckboxBox,
        },
      ],
    },
    {
      name: "listing",
      label: "Listing Status",
      icon: <Content fill={themeColor} />,
      section: "listing",
      checkbox: [
        {
          name: "newly",
          label: "New listing",
          component: CheckboxBox,
        },
        {
          name: "reListed",
          label: "Reseen",
          component: CheckboxBox,
        },
        {
          name: "deListed",
          label: "Not Seen",
          component: CheckboxBox,
        },
      ],
    },
  ],
  delivery: [
    {
      name: "email",
      label: "E-mail",
      component: CheckboxBox,
      icon: <Email fill={themeColor} />,
    },
    // {
    //   name: "sms",
    //   label: "SMS",
    //   component: CheckboxBox,
    //   icon: <Sms />,
    // },
    // {
    //   name: "whatsApp",
    //   label: "WhatsApp",
    //   component: CheckboxBox,
    //   icon: <WhatsApp />,
    // },
  ],
  schedule: {
    type: {
      name: "schedule",
      default: "Choose",
      options: [
        { lable: "Daily", value: "daily" },
        { lable: "Weekly", value: "weekly" },
        { lable: "Monthly", value: "monthly" },
      ],
    },
    days: [
      { label: "Monday", id: 0 },
      { label: "Tuesday", id: 1 },
      { label: "Wednesday", id: 2 },
      { label: "Thursday", id: 3 },
      { label: "Friday", id: 4 },
      { label: "Saturday", id: 5 },
      { label: "Sunday", id: 6 },
    ],
    time: {
      hours: {
        am: [
          { label: "00", value: "00" },
          { label: "01", value: "01" },
          { label: "02", value: "02" },
          { label: "03", value: "03" },
          { label: "04", value: "04" },
          { label: "05", value: "05" },
          { label: "06", value: "06" },
          { label: "07", value: "07" },
          { label: "08", value: "08" },
          { label: "09", value: "09" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
        ],
        pm: [
          { label: "12", value: "12" },
          { label: "01", value: "13" },
          { label: "02", value: "14" },
          { label: "03", value: "15" },
          { label: "04", value: "16" },
          { label: "05", value: "17" },
          { label: "06", value: "18" },
          { label: "07", value: "19" },
          { label: "08", value: "20" },
          { label: "09", value: "21" },
          { label: "10", value: "22" },
          { label: "11", value: "23" },
        ],
      },
      minutes: [
        { label: "00", value: "00" },
        { label: "05", value: "05" },
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "20", value: "20" },
        { label: "25", value: "25" },
        { label: "30", value: "30" },
        { label: "35", value: "35" },
        { label: "40", value: "40" },
        { label: "45", value: "45" },
        { label: "50", value: "50" },
        { label: "55", value: "55" },
      ],
    },
  },
});

export const inactiveMetrics = {
  pricing: {
    selected: true,
    changeRange: [0, 100],
    changeRangePercent: [0, 100],
  },
  promotion: {
    selected: true,
    mechanic: {
      priceCut: false,
      multiBuy: false,
      other: false,
    },
    discountRangePercent: [0, 100],
  },
  availability: {
    selected: true,
    available: false,
    unavailable: false,
  },
  listing: {
    selected: true,
    newly: false,
    reListed: false,
    deListed: false,
  },
};

export const activeMetrics = {
  pricing: {
    selected: true,
    changeRange: [0, 100],
    changeRangePercent: [0, 100],
  },
  promotion: {
    selected: true,
    mechanic: {
      priceCut: true,
      multiBuy: true,
      other: true,
    },
    discountRangePercent: [0, 100],
  },
  availability: {
    selected: true,
    available: false,
    unavailable: true,
  },
  listing: {
    selected: true,
    newly: true,
    reListed: true,
    deListed: true,
  },
};
