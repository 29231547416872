import React from "react";
import { COLORS } from "assets/colors/colors";
import { changeNameRetailer } from "utils/changeNameRetailer";
import useColorTheme from "../hooks/useColorTheme";

const BrandLabelCard = ({ name, color, more, onClick, isSelected, score }) => {
  const { primaryColor } = useColorTheme();

  return (
    <div
      onClick={onClick}
      className="label-box"
      style={{
        backgroundColor: more || name === "Select All" ? primaryColor : null,
        border: isSelected || more || name === "Select All" ? `solid 1px ${primaryColor}` : "solid 1px #dbdbdd",
      }}
    >
      {more || name === "Select All" || name === "Clear All" ? null : (
        <span className="color" style={{ backgroundColor: color }}></span>
      )}
      <span
        className="name"
        style={{
          fontWeight: isSelected || name === "Select All" || name === "Clear All" ? "bold" : null,
          fontSize: score ? 10 : null,
          color: isSelected ? primaryColor : more || name === "Select All" ? "white" : null,
        }}
      >
        {changeNameRetailer(name)} {score || +score === 0 ? `- ${Number.parseFloat(+score || 0).toFixed(1)}%` : null}
      </span>
    </div>
  );
};

export default BrandLabelCard;
