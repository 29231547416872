import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .current-table-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }
  .wrapper-table-box {
    width: 100%;
    overflow-x: auto;
    display: inline-block;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .box-wrapper {
    width: 100%;
    display: flex;
  }
  .title,
  .title-search {
    width: 337px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #dfdfea;
    border-left: none;
    border-right: none;
    .image {
      width: 50px;
      height: 50px;
    }
    span {
      font-family: "Gilroy-Medium";
      width: 310px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #000000;
      padding: 0 10px;
    }

    .search {
      width: 337px;
      position: relative;
      font-size: 14px;
      color: #000000;
      display: flex;
      img {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      input {
        border: none;
        background-color: #eef2f7;
        width: 300px;
        height: 44px;
        border-radius: 2px;
        outline: none;
        padding-left: 40px;
        padding-right: 12px;
      }
      .search-sort-btn {
        left: 300px !important;
      }
    }
  }
  .title-search {
    border: none;
  }
  .search-sort-btn {
    left: 300px !important;
    cursor: pointer;
  }
  .wrapper {
    width: 100%;
    display: flex;
    .no-listed {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .table-item {
      cursor: pointer;
      min-width: 118px !important;
      width: 20% !important;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #dfdfea;
      border-right: none;

      & > span {
        font-family: "Gilroy-Medium";
        font-size: 12px;
        text-align: center;
        color: ${COLORS.darkGray};
        font-weight: bold;
      }
      img {
        width: 25px;
      }
    }
  }
  .table-item.sort {
    flex-direction: row;
    border: none;
  }

  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .popup-box {
      max-width: 840px;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 40px;
      position: relative;
    }
  }
`;
