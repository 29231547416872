import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Popover } from "antd";
import StarRatings from "react-star-ratings";
import { useDispatch, useSelector } from "react-redux";

//Components
import { Styles } from "./style";
import PopupRatingProduct from "components/Popups/PopupRatingProduct/PopupRatingProduct";

//Images
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import sort from "assets/images/sort.svg";
import star from "assets/images/star.svg";

//Utils
import { converToThousands, ratingCalendarTableKeys } from "utils/ratingHelper";
import { formatDate } from "utils/formatDate";
import { getTimePeriod } from "utils/getTimePeriod";

//Actions
import { fetchRatingSummaryProduct, fetchRatingSummaryReviews, fetchRatingSummaryReviewsAll } from "store/rating/ratingSummaryProduct/actions";
import { changeNameRetailer } from "utils/changeNameRetailer";
import useFilters from "hooks/useFilters";

const RatingChart = ({ handleSort, sortId, sortDirection, data, clickedArray, filter }) => {
  const dispatch = useDispatch();
  //Selectors
  const { ratingSummaryProduct, ratingSummaryReviews, ratingSummaryReviewsAll } = useSelector((state) => state.ratingSummaryProduct);
  const {
    lastFilter,
  } = useFilters();
  //constants
  const IS_BRANDS = "isBrands";
  const RETAILER = "retailer";
  const BRAND = "brand";
  const PRODUCT = "product";

  //States
  const [popupData, setPopupData] = useState({});
  const [labelsArr, setLabelsArr] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [avgRating, setAvgRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [combinedCoreRetailers, setCombinedCoreRetailers] = useState([]);

  const convertToPricings = useCallback((arr, type) => {
    return arr.map(({ reviewCount, avgRating, recentReview, item, name, id, coreRetailers, combinedCoreRetailers }) => {
      const idForData = type === IS_BRANDS ? id : item.id;
      const nameForData = type === IS_BRANDS ? name : item.name;
      const entityType = typeof idForData === "number" ? RETAILER : coreRetailers.id ? PRODUCT : BRAND;

      return {
        reviewCount,
        avgRating,
        recentReview: recentReview, 
        name: nameForData,
        id: idForData,
        x: labelsArr.findIndex((el) => el.id === idForData && el.name === nameForData),
        entityType,
        coreRetailers,
        combinedCoreRetailers,
      };
    });
  }, [labelsArr]);

  const pricings = useMemo(() => {
    if (data.length && labelsArr.length) {
      let pi = convertToPricings(data);
      if (clickedArray.length) {
        for (let index = 0; index < clickedArray.length; index++) {
          const pricing = convertToPricings(clickedArray[index].children, IS_BRANDS);
          pi = [...pi, ...pricing];
        }
      }
      return pi;
    }
    return [];
  }, [data, clickedArray, labelsArr, convertToPricings]);

  useEffect(() => {
    if (data.length) {
      const labelsArr = data.map(({ item }) => ({
        name: item.name,
        id: item.id,
      }));
      setLabelsArr(labelsArr);

      if (clickedArray.length) {
        for (let index = 0; index < clickedArray.length; index++) {
          const indexOfMatchedEl = labelsArr.findIndex(({ id }) => id === clickedArray[index].id);
          const labels = clickedArray[index].children.map(({ name, id }) => ({
            name,
            id,
          }));

          for (let i = 0; i < labels?.length; i++) {
            labelsArr.splice(indexOfMatchedEl + i + 1, 0, labels[i]);
          }
        }
      }
    }
  }, [data, clickedArray]);

  const fetchProduct = (id) => {
    const data = {
      coreRetailerIds: id.join('|'),
      limit: "10",
      page: "1",
      sort: "date|desc",
      timePeriod: getTimePeriod(lastFilter.date)
    };
    dispatch(fetchRatingSummaryProduct({ id: id[0] }));
    if(filter[1].value) {
      dispatch(fetchRatingSummaryReviewsAll(data))
    } else {
      dispatch(fetchRatingSummaryReviews(data));
    }

    setShowPopup(true);
  };

  const popupContent = () => {
    return (
      <div className="popup-wrapper">
        {popupData.avgRating ? (
          <div className="rating-popup-rating">
            {popupData.avgRating}
            <img src={star} alt="" />
          </div>
        ) : (
          <span className="no-reviews">No Reviews</span>
        )}
        {popupData.entityType === PRODUCT ? (
          <span onClick={() => {
              const ids = popupData.combinedCoreRetailers || [popupData.coreRetailers.id];
              fetchProduct(ids);        
              setAvgRating(popupData.avgRating);
              setReviewCount(popupData.reviewCount);
              setCombinedCoreRetailers(popupData.combinedCoreRetailers);
            }
          }>Show Reviews</span>
        ) : null}
      </div>
    );
  };

  const getPricings = useMemo(() => pricings.sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0)), [pricings]);

  return (
    <Styles>
      <div className="top-content">
        {ratingCalendarTableKeys.map(({ name, key }) => {
          return (
            <div key={key} className="top-content-item" onClick={() => handleSort(key)}>
              {name}
              <img
                src={sortId === key ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                alt="sort"
                className="image"
              />
            </div>
          );
        })}
      </div>
      <div className="chart-content">
        {getPricings.map((el, index) => {
          return (
            <div
              style={{
                backgroundColor: el.entityType === RETAILER ? "#f5f5f5" : el.entityType === BRAND ? "#FAFAFA" : "#fff",
              }}
              key={index}
              className="chart-content-item"
              onMouseOver={() => setPopupData(el)}
            >
              <div>
                <Popover
                  content={popupContent}
                  title={changeNameRetailer(el.name)}
                  trigger="hover"
                  overlayStyle={{ width: 177 }}
                >
                  <div>
                    {el.avgRating ? (
                      <StarRatings
                        rating={+el.avgRating}
                        starRatedColor="#F9C752"
                        numberOfStars={5}
                        name="rating"
                        isSelectable={false}
                        starDimension="18px"
                        starSpacing="2px"
                        starEmptyColor="#DDE4EC"
                      />
                    ) : (
                      <span className="no-reviews">No Reviews</span>
                    )}
                  </div>
                </Popover>
              </div>

              <div>
                <Popover content={popupContent} title={el.name} trigger="hover" overlayStyle={{ width: 177 }}>
                  <div
                    style={{
                      fontWeight: el.entityType === RETAILER ? "bold" : null,
                      fontFamily: el.entityType === RETAILER ? "Gilroy-ExtraBold" : null,
                    }}
                  >
                    {converToThousands(el.reviewCount)}
                  </div>
                </Popover>
              </div>

              <div>
                <Popover content={popupContent} title={el.name} trigger="hover" overlayStyle={{ width: 177 }}>
                  <div
                    style={{
                      fontWeight: el.entityType === RETAILER ? "bold" : null,
                      fontFamily: el.entityType === RETAILER ? "Gilroy-ExtraBold" : null,
                    }}
                  >
                    {el.recentReview !== null ? `${formatDate(el.recentReview)}` : "-"}
                  </div>
                </Popover>
              </div>
            </div>
          );
        })}
      </div>

      {showPopup && ratingSummaryProduct.success ? (
        <PopupRatingProduct
          product={ratingSummaryProduct}
          reviews={filter[1].value ? ratingSummaryReviewsAll : ratingSummaryReviews}
          closePopup={() => setShowPopup(false)}
          showRetailer={false}
          avgRating={avgRating}
          reviewCount={reviewCount}
          combinedCoreRetailers={combinedCoreRetailers}
          timePeriod={getTimePeriod(lastFilter.date)}
          showAllReviews={filter[1].value}
        />
      ) : null}
    </Styles>
  );
};

export default RatingChart;
