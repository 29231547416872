import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Menu, Dropdown, Modal, Switch, Tooltip } from "antd";
import _, { filter } from "lodash";
import { Styles, ApplyDiv, NotifyDiv } from "./Styles";
import useColorTheme from "../../hooks/useColorTheme";
import useCountry from "../../hooks/useCountry";
import useFilters from "../../hooks/useFilters";
import useReport from "../../hooks/useReport";

import { fetchSettingsYourProfile } from "../../store/settings/settingsYourProfile/actions";
import { setPrepareLogout, logOutRequest } from "store/authorization/actions";
import { sendWatchList } from "store/settings/SettingsWatchlist/actions";
import LoaderBox from "components/LoaderBox";
import AvatarPlaceholder from "components/AvatarPlaceholder";
import TabsExport from "components/TabsExport/TabsExport";
import FilterBox from "./FilterBox";
import DateFilterBox from "./DateFilterBox";

import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import { thousSeparator } from "utils/thousSeparator";
import screenshot from "assets/images/screenshot_photo.jpg";
import { Export } from "assets/icons/icons";

const setDefaults = (params, manufacturer, brands) => {
  params.manufacture = params.manufacture.length ? [...new Set(params.manufacture.concat(manufacturer))] : [];
  params.productBrand = params.productBrand.length ? [...new Set(params.productBrand.concat(brands))] : [];
  return params;
};

const MainFilters = (props) => {
  const { primaryColor } = useColorTheme();
  const {
    statusAuth,
    statusProfileData,
    fetchSettingsYourProfile,
    avatar,
    logOutRequest,
    isSelectAllTimePeriods,
    isSelectAllTimePeriodsHandler,
    settingsYourProfile,
    watchlist,
    watchlistFilter,
    setPrepareLogout,
    prepareLogoutStatus,
    countryId, 
    companyId,
    settingsYourProfileStatus,
    sendWatchList,
  } = props;
  const {
    filters,
    statusFilters,
    lastFilter,
    statusMainFilters,
    universalFiltersStatus,
    fetchMainFilters,
    setWatchlistFilter,
    isFilterChange,
    resetUniversalObj,
    defaultLastFilter,
    statusTempFilters,
    refreshStatus,
    setRefreshStatus,
    tempLastFilter,
  } = useFilters();

  const wrapperRef = useRef(null);
  const buttonDisabled = statusTempFilters === STATE_STATUSES.INIT || (statusTempFilters !== STATE_STATUSES.INIT && !isFilterChange) || !tempLastFilter || tempLastFilter.product === '';
  const [selectItems, setSelectItems] = useState([]);
  const { getCountryFlag, currentCountry, countryOptions, setCountry, changeCountryFilters, countryStatus } = useCountry();
  const [isReset, setIsReset] = useState(false);

  const { acknowledgeAll, numUnread } = useReport();

  useEffect(() => {
    if (!isReset && (changeCountryFilters || refreshStatus === STATE_STATUSES.PENDING)) {
      setIsReset(true);
      setSelectItems([]);
      setRefreshStatus(STATE_STATUSES.READY);
    }
  },[changeCountryFilters, selectItems, refreshStatus, isReset, setRefreshStatus]);

  const handleSetActiveFilter = (filterName, filters) => {
    setSelectItems((prevActiveFilter) => ({
      ...prevActiveFilter,
      [filterName]: filters,
    }));
  };

  const [showExportModal, setShowExportModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const [useWatchlist, setUseWatchlist] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isResetUniversalObj, setIsResetUniversalObj] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([])

  const userManufacturer = [localStorage.getItem("UserManufacturer")];
  const userBrands = JSON.parse(localStorage.getItem("UserBrands"));
  const [watchlistParams] = useState({
    watchlist: false,
    watchlistFilter: {
      sourceType: "",
      category: "",
      manufacture: "",
      productBrand: "",
    },
  });

  useEffect(() => {
    setUseWatchlist(watchlist);
  }, [watchlist]);

  const timerLogout = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (prepareLogoutStatus === STATE_STATUSES.PENDING) {
      timerLogout.current = setTimeout(() => logOutRequest(), 200);
    }

    return () => {
      if (timerLogout.current) {
        clearTimeout(timerLogout.current);
      }
    };
  }, [prepareLogoutStatus, logOutRequest]);

  useEffect(() => {
    if (!avatar) {
      fetchSettingsYourProfile();
      setIsResetUniversalObj(true);
    }
  }, [avatar, fetchSettingsYourProfile]);

  useEffect(() => {
    if (isResetUniversalObj && settingsYourProfileStatus === STATE_STATUSES.READY) {
      resetUniversalObj(countryId, companyId);
      setIsResetUniversalObj(false);
    }
  }, [companyId, countryId, isResetUniversalObj, resetUniversalObj, settingsYourProfileStatus]);

  useEffect(() => {
    const isFetch = Boolean(Object.values(filters)[0]);

    if (!isFetch && universalFiltersStatus === STATE_STATUSES.READY) {
      const params = { ...lastFilter, ...{ timePeriod: getTimePeriod(lastFilter.date) } };
      fetchMainFilters({
        ...params,
        manufacture: params.manufacture || userManufacturer.join("|"),
        watchlist: useWatchlist,
        isStart: true,
      });
    }
  }, [
    lastFilter,
    filters,
    fetchMainFilters,
    useWatchlist,
    universalFiltersStatus,
    statusMainFilters,
    userManufacturer,
  ]);

  const handleExport = () => {
    setShowExportModal(true);
  };

  const applyFilters = (page = null) => {
    isSelectAllTimePeriodsHandler && isSelectAllTimePeriodsHandler();
    const params = { ...lastFilter, ...{ timePeriod: getTimePeriod(lastFilter.date) } };
    setIsShown(false);
    fetchMainFilters({
      ...params,
      watchlist: useWatchlist,
      isStart: false,
    });
    if (page) history.push(page);
  };

  const [isResetDate, setIsResetDate] = useState(false);

  const resetFilters = () => {
    setIsResetDate(false);
    const params = { ...defaultLastFilter, ...{ timePeriod: getTimePeriod(defaultLastFilter.date) } };
    fetchMainFilters({
      ...params,
      watchlist: useWatchlist,
      isStart: false,
    });
  };

  const handleWatchListUse = (event) => {
    const watchlist = event;
    setUseWatchlist(watchlist);
    const watchlistFilterState = {
      sourceType: watchlistFilter.sourceType || [],
      category: watchlistFilter.category || [],
      manufacture: watchlistFilter.manufacture || [],
      productBrand: watchlistFilter.productBrand || [],
    };

    setDefaults(watchlistFilterState, userManufacturer, watchlistFilterState.productBrand.filter(b => userBrands.includes(b)));

    const params = {
      sourceType: watchlistFilterState.sourceType.join("|"),
      category: watchlistFilterState.category.join("|"),
      manufacture: watchlistFilterState.manufacture.join("|"),
      productBrand: watchlistFilterState.productBrand.join("|"),
    };

    const sendParams = { watchlist, watchlistFilter: params };

    sendWatchList(sendParams).then(() => {
      setWatchlistFilter({
        ...sendParams,
        watchlist,
      });
    });
    setWatchlistFilter(sendParams);
    setIsReset(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/alerts">Alerts</Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => setPrepareLogout()}>
        Sign out
      </Menu.Item>
    </Menu>
  );

  const handleChangeCountry = useCallback((el) => {
    setCountry({ countryId: el.id, iso3: el.iso3 });
    setIsReset(false);
  }, [setCountry]);

  const menuCountry = useMemo(() => {
    return countryOptions && countryOptions.length > 0 ? (
      <Menu>
        {_.sortBy(countryOptions, (country) => country.name).map((el) => {
          return currentCountry.countryId === el.id ? (
            <Menu.Item key={el.id} style={{ pointerEvents: "none" }}>
              <img className="top-menu-flat" src={getCountryFlag(el.iso)} alt="flag-btn" />
              {el.name}
            </Menu.Item>
          ) : (
            <Menu.Item key={el.id} onClick={() => handleChangeCountry(el)}>
              <img className="top-menu-btn" src={getCountryFlag(el.iso)} alt="flag-btn" />
              {el.name}
            </Menu.Item>
          );
        })}
      </Menu>
    ) : null;
  }, [getCountryFlag, countryOptions, currentCountry, handleChangeCountry]);
  const render = Object.keys(watchlistFilter).reduce((acc, key) => acc + watchlistFilter[key].length, 0);

  function useOutsideAlerter(ref, isShown, setIsShown) {
    useEffect(() => {
      if (isShown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsShown(false);
            document.removeEventListener("mousedown", handleClickOutside);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isShown, setIsShown]);
  }
  useOutsideAlerter(wrapperRef, isShown, setIsShown);

  //does this on initial load but then we need to leave it there and allow it to be flexible like before

// const filteredProductsData = useMemo(() => {
//   if (defaultLastFilter && defaultLastFilter.product) {
//     const lastFilterProductIds = defaultLastFilter.product.split("|").map(Number);
//     return filters.product.filter(product => lastFilterProductIds.includes(product.id));
//   }
//   //else return lastFilter?
//   return [];
// }, [lastFilter, filters.product]);

// const filteredBrandData = useMemo(() => {
//   if (defaultLastFilter && defaultLastFilter.productBrand) {
//     const lastFilterBrandIds = defaultLastFilter.productBrand.split("|").map(Number);
//     return filters.productBrand.filter(productBrand => lastFilterBrandIds.includes(productBrand.id));
//   }
//   return [];
// }, [defaultLastFilter, filters.productBrand]);


  return (
    <Styles color={primaryColor}>
      {statusAuth !== STATE_STATUSES.PENDING &&
      statusMainFilters === STATE_STATUSES.READY &&
      statusProfileData === STATE_STATUSES.READY &&
      countryStatus === STATE_STATUSES.READY ? (
        <div className={`wrapper-header ${isFetch || isValidate ? "is-wait" : ""}`}ref={wrapperRef}>
          <div className="wrapper-header">
            <div>
              <DateFilterBox
                data={filters.date}
                paramName="date"
                isSelectAllTimePeriods={isSelectAllTimePeriods}
                isSelectAllTimePeriodsHandler={isSelectAllTimePeriodsHandler}
                watchlistParams={watchlistParams}
                activeDate={lastFilter.date}
                handleSetActiveFilter={handleSetActiveFilter}
                isResetDate={isResetDate}
                setIsResetDate={setIsResetDate}
              />
              {/* perhaps here we ensure that whatever is going through filters aligns with activeItems? */}
              {Object.keys(filters).map((item, index) => {
                switch (item) {
                  case "sourceType": {
                    return (
                      <FilterBox
                        key={index}
                        name="retailers"
                        titleParam="label"
                        placeholder="retailer"
                        paramName={item}
                        data={filters[item]}
                        activeItems={lastFilter[item]}
                        watchlistParams={watchlistParams}
                        selectedFilters={selectItems}
                        handleSetActiveFilter={handleSetActiveFilter}
                        isFetch={isFetch}
                        setIsFetch={setIsFetch}
                        isValidate={isValidate}
                        setIsValidate={setIsValidate}
                      />
                    );
                  }
                  case "category": {
                    return (
                      <FilterBox
                        key={index}
                        name="categories"
                        titleParam="name"
                        placeholder="category"
                        paramName={item}
                        data={filters[item]}
                        activeItems={lastFilter[item]}
                        watchlistParams={watchlistParams}
                        selectedFilters={selectItems}
                        handleSetActiveFilter={handleSetActiveFilter}
                        isFetch={isFetch}
                        setIsFetch={setIsFetch}
                        isValidate={isValidate}
                        setIsValidate={setIsValidate}
                      />
                    );
                  }
                  case "manufacture": {
                    return (
                      <FilterBox
                        key={index}
                        name="manufacturers"
                        titleParam="name"
                        placeholder="manufacturer"
                        paramName={item}
                        data={filters[item]}
                        activeItems={lastFilter[item]}
                        watchlistParams={watchlistParams}
                        selectedFilters={selectItems}
                        handleSetActiveFilter={handleSetActiveFilter}
                        isFetch={isFetch}
                        setIsFetch={setIsFetch}
                        isValidate={isValidate}
                        setIsValidate={setIsValidate}
                      />
                    );
                  }
                  case "productBrand": {
                    return (
                      <FilterBox
                        key={index}
                        name="brands"
                        titleParam="name"
                        placeholder="brand"
                        paramName={item}
                        // data={filteredBrandData}
                        data={filters[item]}
                        activeItems={lastFilter[item]}
                        watchlistParams={watchlistParams}
                        selectedFilters={selectItems}
                        handleSetActiveFilter={handleSetActiveFilter}
                        isFetch={isFetch}
                        setIsFetch={setIsFetch}
                        isValidate={isValidate}
                        setIsValidate={setIsValidate}
                      />
                    );
                  }
                  case "productGroup": {
                    return (
                      <FilterBox
                        key={index}
                        name="custom groups"
                        titleParam="name"
                        placeholder="productGroups"
                        paramName={item}
                        data={filters[item]}
                        activeItems={lastFilter[item]}
                        watchlistParams={watchlistParams}
                        selectedFilters={selectItems}
                        handleSetActiveFilter={handleSetActiveFilter}
                        isFetch={isFetch}
                        setIsFetch={setIsFetch}
                        isValidate={isValidate}
                        setIsValidate={setIsValidate}
                      />
                    );
                  }
                  case "product": {
                    return (
                      <FilterBox
                        key={index}
                        name="products"
                        titleParam="title"
                        placeholder="product"
                        paramName={item}
                        // data={filteredProductsData}
                        data={filters[item]}
                        activeItems={lastFilter[item]}
                        watchlistParams={watchlistParams}
                        selectedFilters={selectItems}
                        handleSetActiveFilter={handleSetActiveFilter}
                        isFetch={isFetch}
                        setIsFetch={setIsFetch}
                        isValidate={isValidate}
                        setIsValidate={setIsValidate}
                      />
                    );
                  }
                }
              })}
            </div>
            {!buttonDisabled && !isFetch && !isValidate && (
              <ApplyDiv color={primaryColor} disabled={buttonDisabled}>
                <span className={`${!isValidate ? 'pulse-button' : 'disabled'}`} onClick={applyFilters}>
                    Apply
                </span>
              </ApplyDiv>
            )}
          </div>
          <div className="top-menu">
            {countryOptions && countryOptions.length > 0 ? (
              <Dropdown overlay={menuCountry} trigger="click" getPopupContainer={(trigger) => trigger.parentElement}>
                <img className="top-menu-btn" src={currentCountry.countryFlag} alt="flag-btn" />
              </Dropdown>
            ) : (
              <img className="top-menu-flat" src={currentCountry.countryFlag} alt="flag-btn" />
            )}
            <div onClick={handleExport} className="top-menu-btn">
              <Export color={"#8A99A8"} />
            </div>
         
              <NotifyDiv color={primaryColor}>
              {numUnread ? (
                <Tooltip color={'#fafafa'} placement="bottomLeft" title={
                  <div style={{
                    fontFamily: "Gilroy-Regular",
                    color: 'black',
                    backgroundColor: '#fafafa',
                  }}>
                    {numUnread === 1 ? '1 New Report' : `${numUnread} New Reports`} Downloaded
                  </div>
                }>
                  <span className={`notify-button`} onMouseOut={acknowledgeAll} />
                </Tooltip>
                ) : null}

              </NotifyDiv>
            {/*<img onClick={() => setShowNotificationModal(true)} className="top-menu-btn" src={notificationIcon} alt="notification-btn" />*/}
            <Dropdown overlay={menu} trigger="click" getPopupContainer={(trigger) => trigger.parentElement}>
              {!!avatar ? (
                <img className="avatar-placeholder site-dropdown-context-menu" src={avatar} alt="image placeholder" />
              ) : (
                <AvatarPlaceholder
                  firstName={settingsYourProfile.first_name}
                  lastName={settingsYourProfile.last_name}
                  style={{ width: 40, height: 40, fontSize: 14, marginLeft: 10 }}
                />
              )}
            </Dropdown>
          </div>
        </div>
      ) : (
        <LoaderBox isOpacity={false} style={{ height: "100px" }} />
      )}
      {statusAuth !== STATE_STATUSES.PENDING && statusFilters === STATE_STATUSES.READY ? (
        <div className="filter-text-container">
          <div className="filter-text-wrapper">
            Based on your filters we found
            <span className="bold"> {thousSeparator(lastFilter.product.split("|").length)} Products. </span>
            <button className={"reset-button"} onClick={resetFilters}>
              Reset
            </button>
          </div>
          {render ? (
            <div className="switch-container">
              <div className="switch-title">Watchlist :</div>
              <Switch
                checkedChildren="on"
                unCheckedChildren="off"
                checked={useWatchlist}
                onChange={handleWatchListUse}
                className="switch-checked"
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <Modal
        className="export-modal"
        destroyOnClose={true}
        visible={showExportModal}
        onCancel={() => setShowExportModal(false)}
      >
        <TabsExport onClose={() => setShowExportModal(false)} />
      </Modal>
      <Modal className="export-modal" visible={showNotificationModal} onCancel={() => setShowNotificationModal(false)}>
        <img style={{ width: "100%" }} src={screenshot} alt="screenshot" />
      </Modal>
    </Styles>
  );
};

export default connect(
  (state) => ({
    settingsYourProfile: state.settingsYourProfile.settingsYourProfile,
    statusAuth: state.authorization.status,
    watchlist: state.authorization.watchlist,
    watchlistFilter: state.authorization.watchlistFilter,
    retailers: state.settingsYourProfile.settingsYourProfile.retailers,
    statusTempFilters: state.tempFilters.status,
    avatar:
      state.settingsYourProfile.settingsYourProfile.avatar ||
      state.settingsYourProfile.settingsYourProfile.company?.avatar,
    statusProfileData: state.settingsYourProfile.status,
    prepareLogoutStatus: state.authorization.prepareLogoutStatus,
    countryId: state.settingsYourProfile.settingsYourProfile.countryId,
    companyId: state.settingsYourProfile.settingsYourProfile.companyId,
    settingsYourProfileStatus: state.settingsYourProfile.status,
  }),
  {
    fetchSettingsYourProfile,
    sendWatchList,
    setPrepareLogout,
    logOutRequest,
  }
)(MainFilters);