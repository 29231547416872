import { success, error } from "redux-saga-requests";
import {
  FETCH_ALERT_FILTERS,
  RESET_ALERT_FILTERS,
  GET_ALL_ALERTS,
  CREATE_ALERT,
  DELETE_ALERT,
  GET_ALERT,
  TEST_ALERT,
  SET_PRISTINE_ALERTS,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  filters: {},
  alerts: [],
  alert: {},
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALERT_FILTERS:
    case DELETE_ALERT:
    case GET_ALL_ALERTS:
    case GET_ALERT:
    case TEST_ALERT:
    case CREATE_ALERT: {
      return processReducer(state);
    }
    case success(FETCH_ALERT_FILTERS): {
      return {
        ...state,
        filters: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(DELETE_ALERT): {
      return {
        ...state,
        alerts: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(GET_ALL_ALERTS): {
      return {
        ...state,
        alerts: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(GET_ALERT): {
      return {
        ...state,
        alert: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(TEST_ALERT): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case success(CREATE_ALERT): {
      return {
        ...state,
        alerts: [...state.alerts, action.data],
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_ALERT_FILTERS):
    case error(DELETE_ALERT):
    case error(GET_ALL_ALERTS):
    case error(GET_ALERT):
    case error(TEST_ALERT):
    case error(CREATE_ALERT): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case RESET_ALERT_FILTERS: {
      return {
        ...state,
        filters: {},
      };
    }

    case SET_PRISTINE_ALERTS: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
