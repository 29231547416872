import React, { useState, useEffect, useRef, useMemo } from "react";
import { Empty } from "antd";
import useRetailers from "../../../hooks/useRetailers";

import { ArrowSelect } from "assets/icons/icons";
import search from "assets/images/search.svg";

const FiltersWatchlist = ({
  item,
  selectItems,
  setFiltersParams,
  textSelectStyles,
  setOnclickId = () => {},
  onlyDropdown,
  setCurrentForm,
  index,
  handleNextForm,
}) => {
  const { name, label, placeholder, nameParam, data, customLabelName, notIncludeSearch, notIncludeCount } = item;
  console.log('!!item=', item)
  const wrapperRef = useRef(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { getRemoteLogo } = useRetailers();

  useEffect(() => {
    setSelectedItems(selectItems);
  }, [selectItems]);

  const selectedWatchlist = useMemo(() => {
    return (!!data && data.filter((el) => selectedItems.includes(el.id)).map((el) => el.id)) || [];
  }, [data, selectedItems]);

  const onSearch = (data) =>
    data.filter((item) => {
      if (searchValue) {
        return item[nameParam].toLowerCase().includes(searchValue.toLowerCase());
      }
      return item;
    });

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);
  };

  const handleSelectItem = (id) => {
    if (selectedWatchlist.includes(id)) {
      const ids = selectedWatchlist.filter((item) => item !== id);
      setSelectedItems(ids);
      setOnclickId(ids);
    } else {
      const ids = [...selectedWatchlist, id];
      setSelectedItems(ids);
      setOnclickId(ids);
    }
  };

  const handleSelectedAll = () => {
    const ids = data.map((item) => item.id);
    setSelectedItems(ids);
  };

  const handleApply = () => {
    setFiltersParams(name, selectedWatchlist);
    setOpenDropdown(false);
  };

  const handleNext = () => {
    handleNextForm(name, selectedWatchlist);
  };

  const selectedItemsIcon = () => {
    const items = data.filter((item) => selectItems.includes(item.id));
    return (
      <div className="selected-list">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {placeholder === "retailer" ? (
              <img
                src={getRemoteLogo((item[nameParam] || "").replaceAll(" ", "_"))}
                alt="retailer"
                style={{ zIndex: index, right: index * 10 }}
              />
            ) : (
              <div style={{ background: item.color }}></div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  console.log('!!selectItems=', selectItems)
  console.log('!!selectItems.length=', selectItems.length)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (openDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpenDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, openDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} className="wrapper-custom-select-box">
      {onlyDropdown ? null : (
        <div className="wrapper-custom-select" onClick={() => setOpenDropdown(!openDropdown)}>
          <div className="custom-select-label" style={{ backgroundColor: openDropdown ? "#d9dfe6" : "#eef2f7" }}>
            <div className={`select-label ${selectItems.length ? "selected-label" : ""}`} style={textSelectStyles}>
              <div className="label">{customLabelName ? customLabelName(selectedWatchlist.length) : label}</div>
              {selectItems.length && !customLabelName ? (
                <div className="selected-length">({selectedWatchlist.length})</div>
              ) : null}
            </div>
            <div className="hint-selected-item">
              {selectItems.length ? selectedItemsIcon() : null}
              <ArrowSelect />
            </div>
          </div>
        </div>
      )}

      {openDropdown || onlyDropdown ? (
        <div
          className="custom-select-dropdown"
          style={{
            position: onlyDropdown ? "static" : "absolute",
            width: onlyDropdown ? "540px" : "320px",
          }}
        >
          {notIncludeSearch ? null : (
            <div className="search-item">
              <img src={search} alt="search" />
              <input onChange={handleSearch} value={searchValue} placeholder={`Search for a ${placeholder}`} />
            </div>
          )}
          {notIncludeCount ? null : (
            <div className="count-items">
              <div>
                {data.length} {label}
              </div>
              <div>{selectedWatchlist.length} Selected</div>
              {/* {placeholder === "brand" && historicBrands.length > 0 && (
                <div style={{ fontStyle: "italic" }}>({historicBrands.length} Historic)</div>
              )} */}
            </div>
          )}
          <div className="select-lists">
            {onSearch(data).length ? (
              onSearch(data).map((item, index) => (
                <div
                  key={index}
                  className={`select-item-box ${selectedWatchlist.includes(item.id) ? "selected-item-box" : ""}`}
                  onClick={() => handleSelectItem(item.id)}
                >
                  <div className="select-item">
                    {placeholder === "retailer" ? (
                      <img src={getRemoteLogo((item[nameParam] || "").replaceAll(" ", "_"))} alt="retailer" />
                    ) : (
                      <div style={{ background: item.color }} className="select-color"></div>
                    )}
                    <div className="select-name">{item[nameParam]}</div>
                  </div>
                  {item.hasOwnProperty("productsCount") ? (
                    <div className="select-count">({item.productsCount})</div>
                  ) : null}
                </div>
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
          <div className="control-select-btn">
            {selectedWatchlist.length === data.length || selectedWatchlist.length > data.length ? (
              <div onClick={() => setSelectedItems([])}>Deselect All</div>
            ) : selectedWatchlist.length !== data.length && selectedWatchlist.length > 0 ? (
              <div onClick={() => setSelectedItems([])}>Reset</div>
            ) : (
              <div onClick={handleSelectedAll}>Select All</div>
            )}

            {onlyDropdown ? null : (
              <div className="apply" onClick={handleApply}>
                Apply
              </div>
            )}
          </div>
        </div>
      ) : null}
      {onlyDropdown ? (
        <div className="wrapper-control-btn">
          {index !== 0 ? (
            <div className="default-btn" onClick={() => setCurrentForm(index - 1)}>
              Previous
            </div>
          ) : null}
          {index === 3 ? (
            <div className={`primary-btn ${!selectedWatchlist || selectedWatchlist.length === 0 ? 'disabled' : '' }`} onClick={handleApply}>
              Save
            </div>
          ) : (
            <div className={`primary-btn ${!selectedWatchlist || selectedWatchlist.length === 0 ? 'disabled' : '' }`} onClick={handleNext}>
              Next
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FiltersWatchlist;
