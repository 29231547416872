import React from "react";

import { Styles } from "./Styles";
import productImage from "../../assets/images/testImage.png";
import BrandLabelCard from "components/BrandLabelCard";
import { BRAND, PRODUCT, RETAILER } from "utils/currentContentHelper";
import useRetailers from "../../hooks/useRetailers";

const ProductItem = ({ name, score, image }) => {
  const { getRemoteLogo } = useRetailers();
  return (
    <div className="retailer-box">
      <div className="wrapper-prod">
        <img className="image" src={image.startsWith("data") ? productImage : image} alt="banner" />
        <img className="retailer-marcker" src={getRemoteLogo(name)} alt="retailer" />
      </div>
      <span className="retailer-box-value product">{Number.parseFloat(score).toFixed(1)}%</span>
    </div>
  );
};

const RetailerItem = ({ name, score }) => {
  const { getRemoteLogo } = useRetailers();
  return (
    <div className="retailer-box">
      <img src={getRemoteLogo(name)} alt="" className="retailer-box-image" />
      <span className="retailer-box-value">{Number.parseFloat(+score || 0).toFixed(1)}%</span>
    </div>
  );
};

const ContentScrollBox = ({ data, type, setSelectedFilter, selectedFilter }) => {
  const clickHandler = (name) => {
    if (selectedFilter.value === name) {
      setSelectedFilter({});
    } else {
      setSelectedFilter({ type: BRAND, value: name });
    }
  };

  return (
    <Styles>
      <div className="row-brands" style={{ height: type === PRODUCT ? 78 : type === BRAND ? 66 : 68 }}>
        {data.map(({ name, score, retailer, image, color }, index) => {
          if (type === BRAND) {
            return (
              <div key={index}>
                <BrandLabelCard
                  name={name}
                  key={index}
                  onClick={() => clickHandler(name)}
                  isSelected={selectedFilter.value === name ? true : false}
                  color={color}
                  score={score}
                />
              </div>
            );
          }
          if (type === RETAILER) {
            return (
              <div key={index}>
                <RetailerItem name={name} score={score} />{" "}
              </div>
            );
          }
          if (type === PRODUCT) {
            return (
              <div key={index}>
                <ProductItem name={retailer} score={score} image={image} />{" "}
              </div>
            );
          }
        })}
      </div>
    </Styles>
  );
};

export default ContentScrollBox;
