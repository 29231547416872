import React from "react";
import { Slider } from "antd";

const SliderBox = ({ values, setValues }) => {
  return (
    <div>
      <Slider range min={0} max={100} value={values} onChange={setValues} />
      <div className="wrapper-slider-inputs">
        <input type="text" disabled={true} value={values[0]} />
        <div className="separete">-</div>
        <input type="text" disabled={true} value={values[1]} />
      </div>
    </div>
  );
};

export default SliderBox;
