import React, { useState } from "react";
import { Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
//Components
import { Styles } from "./styles";
import PopupCurrentContent from "components/Popups/PopupCurrentContent";
import RenderNoData from "components/RenderNoData";
import TotalScore from "components/TotalScore";
import LoaderBox from "components/LoaderBox";
//Utils
import { getImage } from "utils/getImage";
import useRetailers from "../../../../../hooks/useRetailers";
import { STATE_STATUSES } from "utils/statuses";
import { renderScoreImg, specifications } from "utils/currentContentHelper";
//Images
import productImage from "assets/images/testImage.png";
import searchIcon from "assets/images/search.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";

//Actions
import { fetchCurrentContenPopup } from "store/content/currentContentTable/actions";

const CurrentBottomContent = (props) => {
  const { data, handleSearch, handleSort, sortId, sortDirection, search } = props;
  const dispatch = useDispatch();
  const { product, status } = useSelector((state) => state.currentContentTable);

  const [showPopup, setShowPopup] = useState(false);
  const [dataForPopup, setDataForPopup] = useState({
    noIssue: 0,
    improve: 0,
    change: 0,
  });
  const { getRemoteLogo } = useRetailers();

  const popupContent = () => {
    const { noIssue, improve, change, id } = dataForPopup;
    return (
      <div className="popup-wrapper">
        <div className="popup-row">
          <span>No issue</span>
          <span>{noIssue}</span>
        </div>
        <div className="popup-row">
          <span>To be improved</span>
          <span>{improve}</span>
        </div>
        <div className="popup-row">
          <span>Needs change</span>
          <span>{change}</span>
        </div>

        <span className="show-details" onClick={() => fetchPopupData(id).then(() => setShowPopup(true))}>
          Show Details
        </span>
      </div>
    );
  };

  const fetchPopupData = async (id) => {
    return dispatch(fetchCurrentContenPopup(id));
  };

  const onElHover = (el) => {
    let noIssue = 0;
    let improve = 0;
    let change = 0;

    Object.entries(el).forEach((item) => {
      if (item[0].includes("Score") && item[0] !== "totalScore") {
        if (item[1] >= 85 && item[0]?.includes("titles")) {
          return (noIssue += 1);
        } else if (item[1] === 100 && item[0]?.includes("secondary")) {
          return (noIssue += 1);
        } else if (item[1] !== 100 && item[0]?.includes("secondary")) {
          return (change += 1);
        } else if (item[1] >= 95) {
          return (noIssue += 1);
        } else if (item[1] <= 49) {
          return (change += 1);
        } else {
          return (improve += 1);
        }
      }
    });

    setDataForPopup({ noIssue, improve, change, id: el.id });
  };

  const renderCellItem = (el) => {
    return specifications.map(({ key }, index) => {
      return (
        <Popover
          content={popupContent}
          title="Opportunities"
          trigger="hover"
          align={{ offset: [0, 25] }}
          key={index}
          overlayStyle={{ width: 160 }}
        >
          <div className="table-item">
            <>
              {key === "retailer" ? <img src={getRemoteLogo(el[key].name)} alt="" /> : null}
              {key === "totalScore" ? <TotalScore data={el[key].toFixed(1)} inTable /> : null}
              {key !== "totalScore" && key !== "retailer" ? (
                <img src={getImage(renderScoreImg(el[key], key))} alt="" />
              ) : null}
            </>
          </div>
        </Popover>
      );
    });
  };

  return (
    <Styles>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="title-search">
                <div className="search">
                  <img src={searchIcon} alt="search" />
                  <input
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search for a Product"
                    value={search}
                  />
                  <img
                    src={sortId === "productName" ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                    alt="sort"
                    className="search-sort-btn"
                    onClick={data.count === 0 ? () => {} : () => handleSort("productName")}
                  />
                </div>
              </div>
              <div className="wrapper">
                {data.products.length
                  ? specifications.map((item, index) => {
                      let key;
                      if (item.key === "sourceTypeSort") {
                        key = "sourceTypeSort";
                      } else {
                        key = item.key;
                      }
                      return (
                        <div
                          className="table-item sort"
                          key={index}
                          onClick={data.count === 0 ? () => {} : () => handleSort(key)}
                        >
                          <span className="">{item.value}</span>

                          <img src={sortId === key ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div className="wrapper-table">
            <div className="wrapper-box-relative">
              {data.count > 0 ? (
                data.products.map((el, index) => (
                  <div className="box-wrapper" key={index}>
                    <div className="title">
                      <img className="image" src={el.image.startsWith("data") ? productImage : el.image} alt="banner" />
                      <span>{el.name}</span>
                    </div>

                    <div
                      className="wrapper"
                      onMouseOver={() => onElHover(el)}
                      onClick={() => fetchPopupData(el.id).then(() => setShowPopup(true))}
                    >
                      {renderCellItem(el)}
                    </div>
                  </div>
                ))
              ) : (
                <RenderNoData />
              )}
            </div>
          </div>
        </div>
      </div>
      {showPopup && status === STATE_STATUSES.READY ? (
        <PopupCurrentContent
          product={product.product}
          coreProduct={product.coreProduct}
          closePopup={() => setShowPopup(false)}
        />
      ) : null}
    </Styles>
  );
};

export default CurrentBottomContent;
