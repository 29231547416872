import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Button, Tooltip } from "antd";
import useRetailers from "../../hooks/useRetailers";
import { getTimePeriod } from "utils/getTimePeriod";
import { thousSeparator } from "utils/thousSeparator";


import search from "assets/images/search.svg";
import down from "assets/images/down.svg";
import { STATE_STATUSES } from "utils/statuses";
import useFilters from "../../hooks/useFilters";
import useCountry from "../../hooks/useCountry";
import { CloseCircleOutlined, SortAscendingOutlined, FilterOutlined, FilterTwoTone, StopOutlined } from "@ant-design/icons";
import useColorTheme from "hooks/useColorTheme";
const SORT_ORDER = {
 SELECTED: "selected",
 UNSELECTED: "unselected",
 ASC: "ascending",
};
const ACTION_SELECT_ALL = "Select All";
const ACTION_CLEAR_NEW_SELECTION = "Clear New Selection";
const ACTION_CLEAR_ALL = "Clear All";

const FilterBox = (props) => {
 const wrapperRef = useRef(null);


 const { name, titleParam, placeholder, data, activeItems, paramName, handleSetActiveFilter, selectedFilters, isFetch, setIsFetch, setIsValidate } = props;


 const [isOpen, setIsOpen] = useState(false);
 const [isOpenSybBrand, setIsOpenSybBrand] = useState(false);
 const [searchValue, setSearchValue] = useState("");
 const [selectItems, setSelectItems] = useState(selectedFilters);
 const [filteredData, setFilteredData] = useState([])
 const [indexSubbrand, setIndexSubbrand] = useState(null);
 const { getRemoteLogo } = useRetailers();
 const [sortOrder, setSortOrder] = useState(SORT_ORDER.SELECTED);
 const [sortedValues, setSortedValues] = useState(selectedFilters);
 const {
   fetchTempFilters,
   setEqualValues,
   lastFilter,
   statusFilters,
   mainLastFilter,
   tempLastFilter,
   statusMainFilters,
   universalFiltersStatus,
   validateFilters,
   refreshStatus,
 } = useFilters();
 const { changeCountryFilters } = useCountry();
 const { primaryColor } = useColorTheme();


 const isEmpty = useMemo(() => {
   if (paramName !== "productGroup" && selectItems.length === 0) return true;
   return false;
 }, [paramName, selectItems]);


 const itemsInSelection = useMemo(() => {
   if (!data || !selectItems || !Array.isArray(selectItems)) return [];


   const itemsInMenu = data && Array.isArray(data) ? data.map(el => el.id) : [];
   return itemsInMenu.filter(el => selectItems.includes(`${el}`))
 }, [data, selectItems]);


 const hasOptions = useMemo(() => {
   if (paramName !== "productGroup" && Array.isArray(data) && data.length > 0) {
     return true;
   } else if (
     paramName === "productGroup" &&
     Array.isArray(data.companyProductGroup) &&
     Array.isArray(data.userProductGroup) &&
     (data.companyProductGroup.length > 0 || data.userProductGroup.length > 0)
   ) {
     return true;
   }
   return false;
 }, [paramName, data]);


 const isChange = useMemo(() => {
   const lastFilterArray = lastFilter && lastFilter[paramName] ? lastFilter[paramName].split("|") : [];
   return !(
     (lastFilterArray.length === 0 && selectItems.length === 0) ||
     (selectItems.length > 0 &&
       lastFilterArray.every((item) => selectItems.includes(item)) &&
       selectItems.every((item) => lastFilterArray.includes(item)))
   );
 }, [lastFilter, paramName, selectItems]);


 const productsData = useMemo(() => {
   if (!Array.isArray(data)) {
     const products = [...data.companyProductGroup, ...data.userProductGroup];
     return products;
   }
   return "";
 }, [data]);


 const equalsCheck = (a, b) => a.length === b.length && a.every((aItem) => b.some((bItem) => aItem === bItem));


 const compareFilterSelections = (isASelected, isBSelected) => {
   return isASelected && !isBSelected ? -1 : !isASelected && isBSelected ? 1 : 0;
 };


 const sortData = useCallback((order, selectedItems, inputData) => {
     const selectedItemsSet = new Set(selectedItems.map((id) => id.toString()));
     const sortKey = name === "products" ? "title" : "name";
     const comparator = (a, b) => {
       const isASelected = selectedItemsSet.has(a.id.toString());
       const isBSelected = selectedItemsSet.has(b.id.toString());
       if (order === SORT_ORDER.ASC) {
         return a[sortKey].localeCompare(b[sortKey]);
       } else if (order === SORT_ORDER.SELECTED) {
         return compareFilterSelections(isASelected, isBSelected);
       } else {
         return compareFilterSelections(!isASelected, !isBSelected);
       }
     };
     setSortedValues([...inputData].sort((a, b) => a[sortKey].localeCompare(b[sortKey])).sort(comparator));
     setSortOrder(order);
 }, [name]);


 useEffect(() => {
   if ((paramName === 'productBrand') || (paramName === 'product')) {
    setSortedValues(data);
   };
 }, [data, paramName]);


 useEffect(() => {
   setSelectItems(lastFilter[paramName] ? lastFilter[paramName].split("|").filter((item) => item !== "") : []);
 }, [lastFilter, paramName]);


 const toggleopen = () => {
   if (statusFilters === STATE_STATUSES.READY) setIsOpen(!isOpen);
 };


 const toggleOpenSubBrand = (index) => {
   if (index !== indexSubbrand) {
     setIsOpenSybBrand(true);
   } else {
     setIsOpenSybBrand(!isOpenSybBrand);
   }
   setIndexSubbrand(index);
 };


 const handleSearch = (e) => {
   const { value } = e.target;
   setSearchValue(value);
 };

 const handleClearSearch = () => {
  setSearchValue('');
};


 const onSearch = useCallback((searchItem) => {
   return sortedValues && sortedValues.length > 0
     ? sortedValues.filter((item) => {
         if (searchValue) {
           return item[titleParam].toLowerCase().includes(searchValue.toLowerCase());
         }
         return item;
       })
     : searchItem.filter((item) => {
         if (searchValue) {
           return item[titleParam].toLowerCase().includes(searchValue.toLowerCase());
         }
         return item;
       });
 },
[searchValue, sortedValues, titleParam]);


 const handleSelectItem = (id) => {
   if (Number(id)) {
     if (selectItems.includes(id.toString())) {
       const filtered = selectItems.filter((item) => item !== id.toString());
       setSelectItems(filtered);
       handleSetActiveFilter(paramName, filtered);
     } else {
       const newState = [...selectItems, id.toString()];
       setSelectItems(newState);
       handleSetActiveFilter(paramName, newState);
     }
   } else {
     if (selectItems.includes(id.id.toString())) {
       let selectBrands = selectItems;


       if (id.child.length) {
         const subbrands = id.child.map((item) => item.id.toString());
         const filteredSubbrand = selectItems.filter((el) => !subbrands.includes(el));
         selectBrands = filteredSubbrand;
       }


       const filtered = selectBrands.filter((item) => item !== id.id.toString());
       setSelectItems(filtered);
       handleSetActiveFilter(paramName, filtered);
     } else {
       let items = id.child.length ? id.child.map((item) => item.id.toString()) : [];


       const newState = [...new Set([...selectItems, id.id.toString(), ...items])];
       setSelectItems(newState);
       handleSetActiveFilter(paramName, newState);
     }
   }
 };




 useEffect(() => {
   setFilteredData(onSearch(Array.isArray(data) ? data : productsData));
 }, [searchValue, data, productsData, onSearch]);

const menuAction = useMemo(() => {
  return Array.isArray(data)
  ? selectItems.length === 0
    ? ACTION_SELECT_ALL
    : !equalsCheck(mainLastFilter[paramName].split("|"), selectItems)
    ? ACTION_CLEAR_NEW_SELECTION
    : selectItems.length === filteredData.length || selectItems.length > filteredData.length
    ? ACTION_CLEAR_ALL
    : equalsCheck(mainLastFilter[paramName].split("|"), selectItems) && ACTION_CLEAR_ALL
  : selectItems.length === 0
  ? ACTION_SELECT_ALL
  : !equalsCheck(mainLastFilter[paramName].split("|"), selectItems)
  ? ACTION_CLEAR_NEW_SELECTION
  : selectItems.length === productsData.length || selectItems.length > productsData.length
  ? ACTION_CLEAR_ALL
  : equalsCheck(mainLastFilter[paramName].split("|"), selectItems) && ACTION_CLEAR_ALL
}, [data, filteredData.length, mainLastFilter, paramName, productsData.length, selectItems])

 const handleToggleSelect = () => {
  const dataParam = Array.isArray(data) ? data : productsData;
  const filtered = onSearch(dataParam);

  setFilteredData(filtered);

  if (menuAction === ACTION_CLEAR_ALL) {
    setSelectItems([]);
    handleSetActiveFilter(paramName, []);
  } else if  (menuAction === ACTION_SELECT_ALL) {
    const ids = new Set(selectItems);

    filtered.forEach((item) => {
      ids.add(item.id.toString());
      if (item.child && item.child.length) {
        item.child.forEach((childItem) => ids.add(childItem.id.toString()));
      }
    });

    const idsArray = Array.from(ids);
    setSelectItems(idsArray);
    handleSetActiveFilter(paramName, idsArray);
  } else {
    setSelectItems(mainLastFilter[paramName].split("|"));
    handleSetActiveFilter(paramName, mainLastFilter[paramName].split("|"));
  }
};

 const [dataValidate, setDataValidate] = useState({});
 const [isStartValidate, setIsStartValidate] = useState(false);


 useEffect(() => {
   if (!!selectItems && Array.isArray(selectItems) && isFetch) {
     let updateFilters = {
       ...lastFilter,
       ...{ [paramName]: selectItems.join("|"), timePeriod: getTimePeriod(lastFilter.date) },
     };
     if (paramName === "manufacture") {
       updateFilters = {
         ...updateFilters,
         ...{
           productBrand: "All",
           product: "All",
         },
       };
     }
     if (paramName === "productBrand" || paramName === "productGroup") {
       updateFilters = {
         ...updateFilters,
         ...{
           product: "All",
         },
       };
     }
     setDataValidate(updateFilters);
     setIsValidate(true);
     setTimeout(() => setIsStartValidate(true), 100);
   }
 }, [selectItems, lastFilter, paramName, setIsValidate, isFetch]);


 useEffect(() => {
   if (isStartValidate) {
     validateFilters(dataValidate);
     setIsValidate(false);
     setIsStartValidate(false);
   }
 }, [isStartValidate, dataValidate, setIsValidate, validateFilters]);


  function useOutsideAlerter(
   ref,
   selItems,
   paramName,
   isOpen,
   setIsOpen,
   changeCountryFilters
 ) {
  
   useEffect(() => {
     if (isOpen) {
       function handleClickOutside(event) {
         if (ref.current && !ref.current.contains(event.target) && !changeCountryFilters) {
           setIsOpen(false);
           document.removeEventListener("mousedown", handleClickOutside);
         }
       }
       document.addEventListener("mousedown", handleClickOutside);


       return () => {
         document.removeEventListener("mousedown", handleClickOutside);
       };
     }
   }, [ref, selItems, paramName, isOpen, setIsOpen, changeCountryFilters]);
 }
 useOutsideAlerter(
   wrapperRef,
   selectItems,
   paramName,
   isOpen,
   setIsOpen,
   changeCountryFilters
 );
 const [filtersSelection, setFiltersSelection] = useState({});
 const [isStartFetch, setIsStartFetch] = useState(false);
 const [isReset, setIsReset] = useState(false);
  useEffect(() => {
   if (refreshStatus === STATE_STATUSES.PENDING || changeCountryFilters) {
     setIsReset(false)
   } else if (!isReset && refreshStatus === STATE_STATUSES.READY) {
     setIsReset(true)
     setSortOrder(SORT_ORDER.SELECTED);
     setSortedValues([]);
   }
 }, [refreshStatus, data, selectedFilters, isReset, changeCountryFilters]);


 const applyFilters = useCallback(() => {
   setIsOpen(false);
   let updateFilters = {
     ...lastFilter,
     ...{ [paramName]: selectItems.join("|"), timePeriod: getTimePeriod(lastFilter.date) },
   };
   if (paramName === "manufacture") {
     updateFilters = {
       ...updateFilters,
       ...{
         productBrand: "All",
         product: "All",
       },
     };
   }
   if (paramName === "productBrand") {
     updateFilters = {
       ...updateFilters,
       ...{
         product: "All",
       },
     };
   }


   setFiltersSelection(updateFilters);
   setIsFetch(true);
   setTimeout(() => setIsStartFetch(true), 300);


 }, [selectItems, paramName, lastFilter, setIsFetch]);


 useEffect(() => {
   if (isStartFetch && !isOpen) {
     fetchTempFilters({ ...filtersSelection });
     const filtersDifferent =
       mainLastFilter && { ...filtersSelection } &&
       !equalsCheck(mainLastFilter[paramName].split("|"), filtersSelection[paramName].split("|"))
         ? true
         : false;
      setEqualValues({ [paramName]: filtersDifferent });
      if (paramName !== 'productGroup') {
       sortData(sortOrder, filtersSelection[paramName].split("|"), data);
     }


     setIsFetch(false);
     setIsStartFetch(false);
   }
 }, [refreshStatus, data, fetchTempFilters, filtersSelection, isStartFetch, mainLastFilter, paramName, setEqualValues, sortData, isOpen, setIsFetch, sortOrder]);


 const renderMajorityFilters = (filterBoxItems) => {
   const orgLastSelectedItems = mainLastFilter[paramName].split("|");
   return (
     filterBoxItems.length &&
     onSearch(filterBoxItems).map((item, index) => {
       return (
         <React.Fragment key={index}>
           {name === "brands" ? (
             <div className={`wrapper-item ${item.child.length ? "haveSubbrand" : ""}`} key={index}>
               <div
                 key={index}
                 onClick={() => handleSelectItem(item)}
                 className={`
                                       filter-item ${
                                         selectItems.includes([item.id].toString()) ? "selected-item" : ""
                                       }
                                       ${
                                         orgLastSelectedItems.includes([item.id].toString())
                                           ? "main-lastfilter-item"
                                           : ""
                                       }
                                       ${
                                         item.child.length && selectItems.length
                                           ? `${
                                               !item.child
                                                 .map((item) => item.id.toString())
                                                 .some((brand) => selectItems.includes(brand)) &&
                                               selectItems.includes([item.id].toString())
                                                 ? "selected-some-brand"
                                                 : ""
                                             }`
                                           : ""
                                       }
                                       ${
                                         item.child.length && selectItems.length
                                           ? `${
                                               !item.child
                                                 .map((item) => item.id.toString())
                                                 .every((brand) => selectItems.includes(brand)) &&
                                               selectItems.includes([item.id].toString())
                                                 ? "selected-some-brand"
                                                 : ""
                                             }`
                                           : ""
                                       }
                                       ${
                                         item.child.length && selectItems.length
                                           ? `${
                                               item.child
                                                 .map((item) => item.id.toString())
                                                 .some((brand) => selectItems.includes(brand)) &&
                                               !selectItems.includes([item.id].toString())
                                                 ? "selected-some-brand"
                                                 : ""
                                             }`
                                           : ""
                                       }
                                   `}
               >
                 <span className="color" style={{ background: `${item.color}` }}></span>
                 <span className="name">{item[titleParam]}</span>
                 <span className="count">({item.productsCount})</span>
               </div>
               {isOpenSybBrand && indexSubbrand === index && item.child.length
                 ? item.child.map((item, index) => (
                     <div
                       key={index}
                       onClick={() => handleSelectItem(item.id)}
                       className={`filter-item subbrand ${
                         selectItems.includes([item.id].toString()) ? "selected-item" : ""
                       }`}
                     >
                       <span className="name">{item[titleParam]}</span>
                       <span className="count">({item.productsCount})</span>
                     </div>
                   ))
                 : null}
               {item.child.length ? (
                 <span onClick={() => toggleOpenSubBrand(index)} className="down-box">
                   <img src={down} alt="down" />
                 </span>
               ) : null}
             </div>
           ) : (
             <div
               key={index}
               onClick={() => handleSelectItem(item.id)}
               className={`filter-item ${selectItems.includes([item.id].toString()) ? "selected-item" : ""}
               ${orgLastSelectedItems.includes([item.id].toString()) ? "main-lastfilter-item" : ""}
             `}
             >
               {name !== "retailers" ? (
                 <>
                   <span className="color" style={{ background: `${item.color}` }}></span>
                   <span className="name">{item[titleParam]}</span>
                   {titleParam === "name" ? <span className="count">({item.productsCount})</span> : null}
                 </>
               ) : (
                 <>
                   <img
                     className="img"
                     src={getRemoteLogo((item[titleParam] || "").replaceAll(" ", "_"))}
                     alt="retailer"
                   />
                   <span style={{ textTransform: "capitalize" }} className="name">
                     {item[titleParam]}
                   </span>
                 </>
               )}
             </div>
           )}
         </React.Fragment>
       );
     })
   );
 };
 const renderMinorityFilters = (data) => {
   const getTitle = {
     userProductGroup: "My Custom Groups",
     companyProductGroup: "Company-Wide Custom Groups",
   };


   return (
     Object.entries(data).length !== 0 &&
     Object.keys(data).map((productItem, productIndex) => (
       <div key={productIndex}>
         <div className={`title-product ${productItem}`}>{getTitle[productItem]}</div>
         {data[productItem].length ? (
           data[productItem].map((item, index) => {
             return (
               <div
                 key={index}
                 onClick={() => handleSelectItem(item.id)}
                 className={`filter-item ${selectItems.includes([item.id].toString()) ? "selected-item" : ""}`}
               >
                 <span className="color" style={{ background: `${item.color}` }}></span>
                 <span style={{ textTransform: "capitalize" }} className="name">
                   {item[titleParam]}
                 </span>
                 <span className="count">({item.productsCount})</span>
               </div>
             );
           })
         ) : (
           <div className="no-data">No Data</div>
         )}
       </div>
     ))
   );
 };



 return (
   <div className={`filter-wrapper`} ref={wrapperRef}>
     <div
       className={`filter-btn ${isOpen ? "selected-filter" : ""}
       ${
         statusMainFilters === STATE_STATUSES.READY &&
         universalFiltersStatus === STATE_STATUSES.READY &&
         statusFilters === STATE_STATUSES.READY &&
         mainLastFilter &&
         (isChange || isEmpty)
           ? "current-filter"
           : ""
       }
       ${
         statusMainFilters === STATE_STATUSES.READY &&
         universalFiltersStatus === STATE_STATUSES.READY &&
         statusFilters === STATE_STATUSES.READY &&
         mainLastFilter &&
         tempLastFilter &&
         !equalsCheck(mainLastFilter[paramName].split("|"), tempLastFilter[paramName].split("|"))
           ? "uncommited-change"
           : ""
       }
       ${statusFilters !== STATE_STATUSES.READY || isFetch ? "updating-filter" : ""}
       ${activeItems && activeItems.length ? "active-filter" : ""}`}
       onClick={toggleopen}
     >
       <span>{name}</span>
       {activeItems && activeItems.length ? (
         <span className="select-count">({thousSeparator(activeItems.split("|").length)})</span>
       ) : null}
     </div>
     {isOpen ? (
       <div className={`filter-dropdown ${titleParam === "title" ? "products-box" : ""}`}>
         <div className="filter-list">
           {placeholder !== "productGroups" ? (
             <>
               <div className="search">
                 <img src={search} alt="search" />
                 <input onChange={handleSearch} value={searchValue} placeholder={`Search for a ${placeholder}`} />
                 {searchValue && (
                    <Button className="clear-search" onClick={handleClearSearch}>{<CloseCircleOutlined />}</Button>
                 )}
               </div>
               <div className="count-items" style={{ justifyContent: "space-between" }}>
                 <div>
                   <Tooltip title="Sort">
                     {sortOrder === SORT_ORDER.UNSELECTED ? (
                       <Button icon={<FilterOutlined />} type="text" onClick={() => sortData(SORT_ORDER.ASC, selectItems, data)} />
                     ) : sortOrder === SORT_ORDER.SELECTED ? (
                       <Button
                         icon={<FilterTwoTone twoToneColor={primaryColor} />}
                         type="text"
                         onClick={() => sortData(SORT_ORDER.UNSELECTED, selectItems, data)}
                       />
                     ) : sortOrder === SORT_ORDER.ASC ? (
                       <Button
                         icon={<SortAscendingOutlined />}
                         type="text"
                         onClick={() => sortData(SORT_ORDER.SELECTED, selectItems, data)}
                       />
                     ) : (
                       <Button icon={<StopOutlined />} type="text" onClick={() => sortData(sortOrder, selectItems, data)} />
                     )}
                   </Tooltip>
                 </div>
                 <span style={{ fontStyle: "italic" }}>Sorted: {sortOrder}</span>
               </div>
               <div className="count-items">
                 <span>
                   {filteredData.length ? filteredData.length : data.length} {searchValue ? "Products Found" : name}
                 </span>
                 <span>{itemsInSelection.length} Selected</span>
               </div>
             </>
           ) : null}
           {Array.isArray(data) ? renderMajorityFilters(data) : renderMinorityFilters(data)}
         </div>
         <div className="control">
           <Button
             className={`${hasOptions ? "select" : "select-disabled"}`}
             onClick={handleToggleSelect}
             disabled={!hasOptions}
           >
             {menuAction}
           </Button>
           <Button
             className={`apply ${!isChange || isEmpty ? "disabled" : ""}`}
             onClick={applyFilters}
             disabled={!isChange || isEmpty}
           >
             OK
           </Button>
         </div>
       </div>
     ) : null}
   </div>
 );
};


export default connect((state) => ({
 lastFilter: state.authorization.lastFilter,
 mainLastFilter: state.authorization.lastFilter,
 tempLastFilter: state.tempFilters.filters.lastFilter,
 statusMainFilters: state.filters.status,
}))(FilterBox);
